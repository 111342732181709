import moment from 'moment';
import { currencyFormater } from '@/utils';

export const sizeOptions = [
  {
    label: '2',
    value: 2,
  },
  {
    label: '10',
    value: 10,
  },
  {
    label: '25',
    value: 25,
  },
  {
    label: '50',
    value: 50,
  },
  {
    label: '100',
    value: 100,
  },
];

export const availableColumns = (liveTransferView: boolean): any[] => {
  return [
    {
      dataField: 'leadID',
      displayName: 'ID',
      liveTransfers: false,
      visible: (_: string, corpID: string) => corpID !== 'leadtopia',
    },
    {
      dataField: 'soldDate',
      displayName: 'Date',
      liveTransfers: true,
      formatter: (value: string): string =>
        moment(value).format('MM/DD/YY hh:mm A'),
    },
    {
      dataField: 'campaignName',
      displayName: 'Campaign',
      liveTransfers: false,
    },
    {
      dataField: 'price',
      displayName: 'Price',
      formatter: (value: number): string => currencyFormater.format(value),
      liveTransfers: false,
    },
    {
      dataField: 'firstName',
      displayName: 'First Name',
      liveTransfers: true,
    },
    {
      dataField: 'lastName',
      displayName: 'Last Name',
      liveTransfers: true,
    },
    {
      dataField: 'orgPhone',
      displayName: 'Phone',
      liveTransfers: true,
      visible: (_: string, corpID: string) => corpID !== 'usha',
    },
    {
      dataField: 'email',
      displayName: 'Email',
      liveTransfers: true,
    },
    {
      dataField: 'region',
      displayName: 'Region',
      liveTransfers: true,
    },
    {
      dataField: 'dateOfBirth',
      displayName: 'DOB',
      liveTransfers: true,
    },
    {
      dataField: 'crmContactID',
      displayName: 'CRM ID',
      visible: (_: string, corpID: string) =>
        corpID !== 'leadtopia' && corpID !== 'americafirst',
      liveTransfers: false,
    },
    {
      dataField: 'lastAssignedTo',
      displayName: 'Last Assigned To',
      liveTransfers: false,
      visible: (role: string, corpID: string) =>
        role !== 'user' && corpID !== 'leadtopia' && corpID !== 'americafirst',
    },
    {
      dataField: 'destAgentName',
      displayName: 'Sent To',
      liveTransfers: false,
      visible: (_: string, corpID: string) =>
        corpID !== 'leadtopia' && corpID !== 'americafirst',
    },
  ].filter((c) => {
    if (liveTransferView) {
      return c.liveTransfers;
    }
    return true;
  });
};

export const ranges = [];
