import React, { FC, useEffect, useState } from 'react';
import { Form } from '@availity/form';
import omit from 'lodash.omit';
import { useNotifications, usePageLoader, useUser } from '@/hooks';
import {
  Button,
  Container,
  Row,
  Col,
  Spinner,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
} from 'reactstrap';
import { useMutation } from '@apollo/react-hooks';
import { uuid } from 'uuidv4';
import { updateUserProfile } from '@/graphql/mutations';
import { AgentFragment } from '@/graphql/fragments';
import { useHistory } from 'react-router-dom';

import { WebSocketLink } from 'apollo-link-ws';
import { Auth } from 'aws-amplify';

const sendToken = async (): Promise<any> => {
  try {
    const {
      accessToken: { jwtToken },
      refreshToken: { token },
    }: any = await Auth.currentSession();
    window.postMessage(
      JSON.stringify({ data: { token: jwtToken }, type: 'MP_AUTH' })
    );
  } catch (error) {
    console.error(error);
  }
};

const Sideline: FC = () => {
  const { user } = useUser();
  const history = useHistory();
  const { complete } = usePageLoader();
  const { create: createNotification } = useNotifications();

  const [isSynced, setIsSynced] = useState(
    localStorage.getItem('extensionSynced') === 'true'
  );

  return (
    <>
      <Card className="mt-2">
        <CardHeader className="d-flex justify-content-between">
          <CardTitle
            tag="h5"
            className="py-2 w-100 h4 mb-0 d-flex justify-content-between"
          >
            Agent Helper Extension Sync
          </CardTitle>
        </CardHeader>
        <CardBody>
          <Row className="d-flex justify-content-center">
            <Col>
              <Button
                color="primary"
                onClick={async () => {
                  await sendToken();
                }}
              >
                Sync With Extension
              </Button>
            </Col>
            <Col>{/* <p>{isSynced ? 'Synced' : ' Not Synced'}</p> */}</Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default Sideline;
