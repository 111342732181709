import React, { useEffect, useState, useCallback } from 'react';
import { useQuery } from '@apollo/react-hooks';
import Iframe from 'react-iframe';
import {
  analyticsUrlQuery,
  // analyticsAgentsQuery,
  // leadAggregationQuery,
} from '@/graphql/queries';
import { useUser, usePageLoader } from '@/hooks';
import { AnalyticsSearchBar } from '@/components';
import moment from 'moment';
import omit from 'lodash.omit';
import { lte } from 'lodash';

interface SearchParams {
  dashboardCode: string;
  networkID: string;
  // startDate: string;
  // endDate: string;
  // vendorID?: string;
  // layerID?: string;
  // mpVendorCampaignName?: string;
  // managerID?: string;
}

const createInitialSearchParams = ({
  networkID = '',
  selectedDashboard = {},
}: any): any => {
  let values: any = {
    dashboardCode: selectedDashboard.code || '',
    networkID,
    // startDate: moment().format(),
    // endDate: moment().format(),
  };

  if (!selectedDashboard.filters) {
    return values;
  }

  values = selectedDashboard.filters.reduce((t: any, f: string) => {
    t[f] = undefined;
    return t;
  }, values);

  return values;
};

const Analytics: React.FunctionComponent = () => {
  const { user } = useUser();
  const { complete, start } = usePageLoader();
  const [dashboards, setDashboards] = useState<any[]>([]);
  const [dashboardURL, setDashboardUrl] = useState<string | null>();
  const created: any = {};
  const dateType = 'created';
  const values: any = {};

  const filter = {
    vendorID: values.vendorID || undefined,
    mpVendorCampaignName: values.mpVendorCampaignName || undefined,
    networkID: user.networkID,
    [values.dateType]:
      created.gte !== undefined && created.lte !== undefined
        ? created
        : undefined,
  };

  const [searchParams, setSearchParams] = useState<SearchParams>(
    createInitialSearchParams({
      networkID: user.networkID || '',
      dashboard: {},
    })
  );

  const { data, loading, error, refetch } = useQuery(analyticsUrlQuery, {
    variables: {
      dashboardCode: searchParams.dashboardCode,
      networkID: user.networkID,
      domain: window.location.origin,
      // startDate: searchParams.startDate,
      // endDate: searchParams.endDate,
      // vendorID: searchParams?.vendorID,
      // layerID: searchParams?.layerID,
      // mpVendorCampaignName: searchParams?.mpVendorCampaignName,
      // managerID: searchParams?.managerID,
    },
  });

  const updateSearch = (params: any): void => {
    const {
      dateRange,
      mpVendorCampaignName,
      vendorID,
      layerID,
      managerID,
      dashboardCode,
    } = params;
    refetch({
      dashboardCode,
      networkID: user?.networkID,
      // startDate: dateRange.startDate,
      // endDate: dateRange.endDate,
      // vendorID,
      // layerID,
      // mpVendorCampaignName,
      // managerID,
    });
  };

  const makeIframe = useCallback(() => {
    if (dashboards.length > 0 && dashboardURL) {
      return (
        <Iframe url={dashboardURL} styles={{ border: 0 }} className="h-100" />
      );
    }

    if (loading) {
      return <h3 className="mt-5 text-center">Loading ....</h3>;
    }

    return (
      <h3 className="mt-5 text-center">
        {' '}
        You are not authorized to see this dashboard{' '}
      </h3>
    );
  }, [dashboardURL, loading, dashboards]);

  useEffect(() => {
    if (user.network) {
      const { agentDashboards = [] } = user.network || {};
      if (agentDashboards) {
        const tempDashboards =
          agentDashboards.length > 0 ? agentDashboards : [];
        setDashboards(tempDashboards);
        if (agentDashboards.length > 0) {
          const tempSearch = createInitialSearchParams({
            networkID: user.networkID,
            selectedDashboard: agentDashboards[0],
          });
          refetch(tempSearch);
        }
      }
    }
  }, [user.network, user.networkID, refetch]);

  useEffect(() => {
    setDashboardUrl(data?.analyticsUrl.url || '');
  }, [data]);

  useEffect(() => {
    if (loading) {
      start();
    } else {
      complete();
    }
  }, [loading, start, complete]);

  return (
    <>
      <AnalyticsSearchBar
        handleSubmit={(v: any) => updateSearch(v)}
        network={user.network}
        changeDashboard={(code: string) => {
          const { agentDashboards = [] } = user.network || {};
          const dashboard = agentDashboards.find((d) => {
            return d.code === code;
          });
          const tempSearch = createInitialSearchParams({
            networkID: user.networkID,
            selectedDashboard: dashboard,
          });
          setDashboardUrl(null);
          if (refetch) {
            refetch(tempSearch);
          }
        }}
      />
      <div className="d-flex justify-content-center flex-column h-100 w-100">
        {makeIframe()}
      </div>
    </>
  );
};

export default Analytics;
