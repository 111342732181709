/* eslint-disable unicorn/consistent-function-scoping */
/* eslint-disable promise/always-return */
import React, { useEffect, useState } from 'react';
import { SignUp } from 'aws-amplify-react';
import { Button, Row, Col, Form, FormText, Card, CardTitle } from 'reactstrap';
import { Formik, useField } from 'formik';
import { SelectField } from '@availity/select';
import { Field } from '@availity/form';
import LoadingButton from '@gopro16/button';
import BlockUI from 'react-block-ui';
import { Auth, Storage } from 'aws-amplify';
import { useQuery } from 'react-query';
import '@availity/yup';
import { Vars } from '@/utils';
import * as yup from 'yup';

import AuthHeader from './AuthHeader';

Storage.configure({
  AWSS3: {
    bucket: `report-filters-${Vars.env}`,
    region: 'us-east-1',
  },
});

const isSonic = window.location.href.includes('sonicleads');
const isLeadtopia = window.location.href.includes('leadtopia');
const isAmericafirst = window.location.href.includes('americafirstleads');
const isLocalHost = window.location.href.includes('localhost');

const showHelpEmail = () => {
  if (isLeadtopia) {
    return <a href="mailto:support@leadtopia.com">support@leadtopia.com</a>;
  }

  return (
    <a href="mailto:support@ushamarketplace.com">support@sonicwebdev.com</a>
  );
};

const getInitialRegion = () => {
  if (isLeadtopia) {
    return 'leadtopia_agent';
  }

  if (isAmericafirst) {
    return 'americafirst_agent';
  }

  return isSonic ? 'sonic_agent' : '';
};

const getGroup = (code: string) => {
  switch (code) {
    case 'familyfirst':
      return 'familyfirst';
    case 'someoneelse':
      return 'someoneelse';
    default:
      return undefined;
  }
};

const SelectManagers = () => {
  const [, { value = '' }] = useField('region');

  const { isLoading: isManagersLoading, data: managerData } = useQuery(
    value,
    () =>
      value &&
      fetch(
        `https://lead-arena-config.s3.amazonaws.com/${Vars.env}/${value}/managers.json`
      ).then((res) => res.json()),
    {}
  );

  return (
    <SelectField
      name="managerIDs"
      label="Managers"
      isDisabled={isManagersLoading || !managerData}
      labelKey="label"
      valueKey="key"
      placeholder="Select Managers"
      options={managerData || []}
      isMulti
      isClearable
    />
  );
};

const SelectRegions = () => {
  const {
    isLoading: isRegionsLoading,
    data: regionData,
  } = useQuery('regionData', () =>
    fetch(
      `https://lead-arena-config.s3.amazonaws.com/${Vars.env}/regions.json`
    ).then((res) => res.json())
  );

  if (isRegionsLoading) return null;

  return (
    <SelectField
      name="region"
      label="Network"
      placeholder="Select Company Network"
      options={regionData}
      labelKey="label"
      valueKey="value"
    />
  );
};

class CustomSignUp extends SignUp {
  public constructor(props: any) {
    super(props);

    this._validAuthStates = ['signUp', 'signedOut'];
    // this.signUpFields = ['given_name', 'family_name', 'phone_number', 'email'];
  }

  public showComponent(): JSX.Element {
    let userNamePlaceHolder = 'Email';

    if (!isSonic && !isLeadtopia && !isAmericafirst) {
      userNamePlaceHolder = 'Your USHA Company Email (lowercase only)';
    }

    const queryParams = new URLSearchParams(window.location.search);
    const group = queryParams.get('code') as string;

    if ((isLeadtopia || isLocalHost) && !group) {
      return (
        <AuthHeader cardTitle="Please see your company admin for access">
          <span>
            <p>For help please email {showHelpEmail()}</p>
          </span>
          <div className="d-flex flex-column pt-2">
            <span>
              Have an Account?{' '}
              <Button color="link" onClick={() => this.changeState('signIn')}>
                Sign In
              </Button>
            </span>
          </div>
        </AuthHeader>
      );
    }

    return (
      <AuthHeader cardTitle="Create Your Account">
        <Formik
          onSubmit={async (values: any) => {
            const username = isSonic
              ? values.email.toLowerCase().replace('@', '+1@')
              : values.email.toLowerCase();

            try {
              await Auth.signUp({
                username,
                password: values.password,
                attributes: {
                  email: username,
                  phone_number: `+1${values.phone}`,
                  given_name: values.firstName,
                  family_name: values.lastName,
                  'custom:company': values.region,
                  'custom:managerIDs': JSON.stringify(values.managerIDs),
                  'custom:signUpGroup': group || '',
                },
              });

              if (queryParams.has('forceSignUp')) {
                window.history.replaceState(null, '', '/');
              }

              this.changeState('signIn', values.email);
            } catch (error) {
              this.error(error);
            }
          }}
          initialValues={{
            firstName: '',
            lastName: '',
            region: getInitialRegion(),
            managerIDs: [],
            phone: '',
            email: '',
            password: '',
          }}
          validationSchema={yup.object().shape({
            firstName: yup.string().required('Please enter your first name'),
            lastName: yup.string().required('Please enter your last name'),
            region: yup.string().required('You must select a network.'),
            managerIDs: yup.array(yup.string()).default([]),
            phone: yup
              .string()
              .phone('Please provide a valid phone number')
              .required('Please enter a phone number'),
            email: yup.string().required('Please enter an email'),
            password: yup
              .string()
              .required('Please enter a password')
              .min(6, 'Your password must be more than 6 characters'),
          })}
        >
          {({ isSubmitting, handleSubmit, handleReset }) => (
            <Form onSubmit={handleSubmit} onReset={handleReset}>
              <BlockUI blocking={isSubmitting}>
                <Row>
                  <Col xs={12} md={6}>
                    <Field
                      name="firstName"
                      placeholder="First Name"
                      label="First Name"
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <Field
                      name="lastName"
                      placeholder="last Name"
                      label="Last Name"
                    />
                  </Col>
                </Row>
                {isSonic || isLeadtopia || isAmericafirst ? null : (
                  <>
                    <SelectRegions />
                    <SelectManagers />
                    <FormText>Your current USHA Network</FormText>
                  </>
                )}
                <Field
                  name="phone"
                  type="tel"
                  placeholder="Phone"
                  label="Phone"
                />
                <Field
                  name="email"
                  type="email"
                  placeholder={userNamePlaceHolder}
                  label="Email"
                  errorMessage="Please provide a valid email"
                />
                <Field
                  name="password"
                  type="password"
                  placeholder="Password"
                  label="Password"
                />
              </BlockUI>
              <LoadingButton
                color="primary"
                size="lg"
                loading={isSubmitting}
                disabled={isSubmitting}
                block
              >
                Create Account
              </LoadingButton>
            </Form>
          )}
        </Formik>
        <div className="d-flex flex-column pt-2">
          <span>
            Have an Account?{' '}
            <Button color="link" onClick={() => this.changeState('signIn')}>
              Sign In
            </Button>
          </span>
        </div>
      </AuthHeader>
    );
  }
}

export default CustomSignUp;
