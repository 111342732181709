import gql from 'graphql-tag';

export default gql`
  fragment LeadFragment on Lead {
    id
    __typename
    leadID
    corpID
    agentID
    name
    firstName
    lastName
    email
    campaignID
    campaignName
    destAgentName
    refunded
    refundEligible
    price
    soldDate
    refundStatus
    orgPhone
    povertyLevel
    phones {
      phone
      isPrimary
      carrier
      timeZone
      confidenceScore
      status
      type
    }
    household
    income
    age
    region
    addressOne
    addressTwo
    addressThree
    city
    postalCode
    tier {
      priceTierID
      name
    }
    vendorLayer {
      layerID
      vendorSourceCampaign
    }
    serviceCase {
      serviceCaseID
      agentID
      leadID
      networkID
      leadPhone
      modified
      refundOption
      refundReason
      refundType
      soldDate
      status
      created
      type
      amount
      summary
    }
    dateOfBirth
    lastAssignedTo
    crmContactID
    crmISalesContactID
    crmResult
    destinationEmailResult
    messageID
    leadType
    callerReadyLink
    callerReadyRefundLink
    callID
    recording
    liveTransferSource
    lastResult
  }
`;
