import { useMutation } from '@apollo/react-hooks';
import React from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  ModalProps,
  Form,
} from 'reactstrap';
import { SelectField } from '@availity/select';
import { requestRefundMutation } from '@/graphql/mutations';
import { useNotifications, useUser } from '@/hooks';
import { Formik } from 'formik';
import * as yup from 'yup';

const refundOptions = [
  {
    label: 'Gibbersh',
  },
  // {
  //   label: 'Busy Signal',
  // },
  // {
  //   label: 'A message from the phone company',
  // },
  // {
  //   label: 'A long tone or other non-human type noise',
  // },
  // {
  //   label: 'A fax line',
  // },
  // {
  //   label: 'A call centers automated voice prompt',
  // },
  // {
  //   label: 'The weather line',
  // },
];

interface LeadModalProps extends Omit<ModalProps, 'toggle'> {
  toggle: () => void;
  leadID?: string;
}

const LeadsRefundModal: React.FunctionComponent<LeadModalProps> = ({
  toggle,
  isOpen,
  leadID,
  ...rest
}) => {
  const { create: createNotification } = useNotifications();
  const { user } = useUser();
  const [requestRefund, { loading: updating }] = useMutation(
    requestRefundMutation,
    {
      onError: (error) =>
        createNotification({
          color: 'danger',
          message: error.message.replace('GraphQL error:', ''),
          type: 'alert',
          action: 'MESSAGE',
        }),
      onCompleted: () => {
        createNotification({
          message: 'Refund request submitted.',
          color: 'success',
          type: 'alert',
          action: 'MESSAGE',
        });

        toggle();
      },
    }
  );

  return (
    <Formik
      initialValues={{ refundOption: '' }}
      validationSchema={yup.object().shape({
        refundOption: yup.string().required('This field is required.'),
      })}
      onSubmit={(values) =>
        requestRefund({
          variables: {
            input: {
              leadID,
              refundOption: values.refundOption,
              refundReason: 'Non-Working Number',
            },
          },
        })
      }
    >
      {({ handleSubmit, isSubmitting }) => (
        <Modal size="md" isOpen={isOpen} toggle={toggle} {...rest}>
          <Form onSubmit={handleSubmit}>
            <ModalHeader toggle={toggle}>
              This last result code will trigger a refund request
            </ModalHeader>
            <ModalBody>
              <p className="mb-1 text-muted">
                If this lead is disconnected a refund request will be sent to
                our team to take a look at.
              </p>
              <SelectField
                name="refundOption"
                label="Refund Options"
                options={refundOptions}
                valueKey="label"
              />
              {user?.corpID === 'sonic' && (
                <p className="">
                  By selecting &quot;I AGREE,&quot; you agree to our{' '}
                  <a
                    href="https://www.sonicwebdev.com/return-policy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    refund policy
                  </a>
                  . For questions regarding our refund policy, please call us at
                  (888) 448-7531 or submit a support request at{' '}
                  <a
                    href="https://www.sonicleads.com/support"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.sonicleads.com/support
                  </a>
                </p>
              )}
              <p>
                <b>Please be advised</b> - Making false requests for refunds
                will result in a fee and longer refund timelines for your actual
                non-working numbers.
              </p>
            </ModalBody>
            <ModalFooter>
              <Button color="light" onClick={toggle}>
                Cancel
              </Button>
              <Button
                color="primary"
                type="submit"
                disabled={updating || isSubmitting}
              >
                I AGREE
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

export default LeadsRefundModal;
