/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
  Table,
} from 'reactstrap';
import LoadingButton from '@gopro16/button';
import { useUser, useAccount } from '@/hooks';
import { ProfileAddCreditModal, ProfileAddCardModal } from '@/modals';
import { Vars } from '@/utils';
import { LoadingComponent } from '@/components';
import ChargeRow from './components/ChargeRow';
import AutoFundSettings from './components/AutoFundSettings';
import LeadCommitment from './components/LeadCommitment';

const Account: React.FC = () => {
  const { user, loading: userLoading } = useUser();
  const { account, loading: loadingAccount } = useAccount();
  const [cardModal, setCardModal] = useState(false);
  const [creditModal, setCreditModal] = useState(false);
  const [selectedBroker, setSelectedBroker] = useState({
    brokerID: user.network.brokerID,
    brokerName: user.network.networkName,
  });
  const toggleCardModal = (): void => setCardModal((o) => !o);
  // const toggleCreditModal = (): void => setCreditModal((o) => !o);
  const {
    otherBrokerCredits = [],
    network: { brokerID: networkBrokerID, networkName },
  } = user;

  const toggleCreditModal = (
    brokerID: string = networkBrokerID,
    brokerName: string = networkName
  ): void => {
    setSelectedBroker({ brokerID, brokerName });
    setCreditModal(!creditModal);
  };

  if (userLoading) {
    return <LoadingButton loading />;
  }

  return (
    <>
      <div>
        <div style={{ marginTop: 20 }}>
          {user!.totalCredit < 1 ? (
            <h2>Don&apos;t forget to add credit to your account!</h2>
          ) : null}
          <Row className="mb-3">
            <Col xs={7}>
              <Card>
                <CardHeader className="d-flex justify-content-between">
                  <CardTitle
                    tag="h5"
                    className="py-2 w-100 h4 mb-0 d-flex justify-content-between"
                  >
                    Current Card on File
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <div>
                    {account?.card ? (
                      <table>
                        <thead>
                          <tr>
                            <td style={{ width: 64 }} />
                            <td style={{ width: 64 }} />
                            <td style={{ width: 64 }} />
                            <td style={{ width: 500 }} />
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td style={{ padding: 12 }}>
                              <img
                                alt={account.card.brand}
                                src={`https://s3.amazonaws.com/files.sonicwebdev.com/images/cards/${account.card.brand
                                  .trim()
                                  .replace(' ', '')}.png`}
                                style={{ height: 40, maxWidth: 64 }}
                              />
                            </td>
                            <td style={{ padding: 12 }}>
                              <span style={{ fontSize: 14 }}>last 4</span>
                              <h6 style={{ margin: 0 }}>
                                {account.card.last4}
                              </h6>
                            </td>
                            <td style={{ padding: 12 }}>
                              <span style={{ fontSize: 14 }}>expires</span>
                              <h6 style={{ margin: 0 }}>
                                {account.card.exp_month < 10
                                  ? `0${account.card.exp_month}`
                                  : account.card.exp_month}
                                /{account.card.exp_year}
                              </h6>
                            </td>
                            <td style={{ padding: 12 }}>
                              <ButtonGroup>
                                <Button
                                  color="primary"
                                  onClick={toggleCardModal}
                                >
                                  {account.card ? 'Update Card' : 'Add Card'}
                                </Button>
                                <Button
                                  color="success"
                                  onClick={() => toggleCreditModal()}
                                >
                                  Add Credit
                                </Button>
                              </ButtonGroup>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    ) : (
                      <table>
                        <thead>
                          <tr>
                            <td style={{ width: 240 }} />
                            <td style={{ width: 500 }} />
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            {loadingAccount ? (
                              <LoadingComponent />
                            ) : (
                              <>
                                <td style={{ padding: 12 }}>No card on file</td>
                                <td style={{ padding: 12 }}>
                                  <Button
                                    color="primary"
                                    onClick={toggleCardModal}
                                  >
                                    Add Card
                                  </Button>
                                </td>
                              </>
                            )}
                          </tr>
                        </tbody>
                      </table>
                    )}
                  </div>
                  {user?.network?.refundPolicy && (
                    <div className="mt-2">
                      <a
                        href={user?.network?.refundPolicy}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Refund Policy
                      </a>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
            <Col xs={5}>
              <Card className="h-100">
                <CardHeader className="d-flex justify-content-between">
                  <CardTitle
                    tag="h5"
                    className="py-2 w-100 h4 mb-0 d-flex justify-content-between"
                  >
                    Current Account Credit
                  </CardTitle>
                </CardHeader>
                <CardBody className="d-flex align-items-center justify-content-center">
                  <h4 className="h1 text-success text-center mb-0">{`$${(
                    (user!.totalCredit || 0) / 100
                  ).toFixed(2)}`}</h4>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {user.otherBrokerCredits
            .filter((b) => {
              return b.brokerID !== Vars.sonicBrokerID;
            })
            .map((brokerCredit) => {
              return (
                <Card className="mt-3">
                  <CardHeader className="d-flex justify-content-between">
                    <CardTitle
                      tag="h5"
                      className="py-2 w-100 h4 mb-0 d-flex justify-content-between"
                    >
                      <span>{brokerCredit.brokerName}</span>
                      <span> Available Credit </span>
                    </CardTitle>
                  </CardHeader>
                  <CardBody className="justify-content-between">
                    <Row className="d-flex align-items-center justify-content-between">
                      <Col>
                        <Button
                          color="success"
                          onClick={() =>
                            toggleCreditModal(
                              brokerCredit.brokerID,
                              brokerCredit.brokerName
                            )
                          }
                        >
                          Add Credit
                        </Button>
                      </Col>
                      <Col>
                        <p>
                          These credits can only be spent on leads from this
                          broker and are not transferable to your other account
                          credit buckets.
                        </p>
                      </Col>
                      <Col style={{ alignItems: 'right' }}>
                        <Row style={{ justifyContent: 'right' }}>
                          <p className="h1 text-success text-center mb-0">{`$${(
                            (brokerCredit.credits || 0) / 100
                          ).toFixed(2)}`}</p>
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              );
            })}

          <div className={!account?.card ? 'd-none' : ''}>
            <AutoFundSettings
              agentID={user.agentID}
              viewLoading={userLoading}
              {...user.autoFundSettings}
            />
          </div>
        </div>
        <div className={!account?.card ? 'd-none' : ''}>
          <LeadCommitment
            agentID={user.agentID}
            viewLoading={userLoading}
            leadBuyInAmount={user.leadBuyInAmount}
          />
        </div>

        <Card className="mt-3">
          <CardHeader className="d-flex justify-content-between">
            <CardTitle
              tag="h5"
              className="py-2 w-100 h4 mb-0 d-flex justify-content-between"
            >
              Billing History
            </CardTitle>
          </CardHeader>
          <CardBody>
            <Table className="text-right" hover striped>
              <thead>
                <tr>
                  <th style={{ width: '16px' }} />
                  <th style={{ width: '160px' }}>Status</th>
                  <th style={{ width: '160px' }}>Date</th>
                  <th style={{ width: '160px' }}>Time</th>
                  <th>Amount</th>
                  <th style={{ width: '80px' }}>Currency</th>
                  <th style={{ width: '200px' }}>Purchasing CC #</th>
                </tr>
              </thead>
              <tbody>
                {account?.charges.map((c: any) => (
                  <ChargeRow
                    amount={c.amount}
                    chargeStatus={
                      c.captured === false && c.status !== 'failed'
                        ? 'uncaptured'
                        : c.status
                    }
                    currency="USD"
                    key={c.id}
                    last4={c.source.last4}
                    timestamp={c.created * 1000}
                  />
                ))}
                <tr>
                  <td colSpan={7}>
                    <LoadingComponent loading={loadingAccount} />
                  </td>
                </tr>
              </tbody>
            </Table>
          </CardBody>
        </Card>
        <ProfileAddCreditModal
          toggle={toggleCreditModal}
          isOpen={creditModal}
          broker={selectedBroker}
        />
        <ProfileAddCardModal
          toggle={toggleCardModal}
          isOpen={cardModal}
          corpID={user.corpID}
        />
      </div>
    </>
  );
};

export default Account;
