import React, { useMemo, useState, useEffect } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import { useDestinations, useUser } from '@/hooks';
import { FaPlus } from 'react-icons/fa';
import VanillaSoftCRMProfile from './VanillaSoftCRMProfile';
import ISalesCRMProfile from './ISalesCRMProfile';
import EmailDestinationProfile from './EmailDestinationProfile';
import useProfile from '../../useProfile';
import TextdripProfile from './TextdripProfile';
import CustomAPIProfile from './custom';
import OnlySalesProfile from './OnlySalesProfile';
import GoHighLevelProfile from './GoHighLevelCRMProfile';

const CRMProfiles: React.FC = () => {
  const {
    data: {
      agentID,
      crmProfiles,
      flags,
      network: { availibleCRMs },
    },
  } = useProfile();
  const { user: currentUser } = useUser();
  const { destinations, loading: loadingDestinations } = useDestinations(
    agentID
  );
  const [activeTab, setActiveTab] = useState<string | undefined>();

  const {
    vanillaSoftDirect = {},
    iSalesCRM = {},
    textdrip = {},
    onlysales = {},
    gohighlevel = {},
  } = crmProfiles;

  useEffect(() => {
    if (!activeTab && availibleCRMs) {
      setActiveTab(Object.keys(availibleCRMs)[0]);
    } else if (!activeTab && currentUser.flags.allowCustomAPIs) {
      setActiveTab(destinations?.[0]?.destID || 'custom');
    }
  }, [
    availibleCRMs,
    activeTab,
    destinations,
    currentUser.flags.allowCustomAPIs,
  ]);

  const apiDestinations = useMemo(
    () => destinations.filter((d) => d.destinationType === 'api'),
    [destinations]
  );
  const isMe = agentID === currentUser.agentID;
  const canEdit = ['super_admin', 'manager'].includes(currentUser.role);

  const crmTabs = useMemo(
    () =>
      Object.keys(availibleCRMs || {})
        .map(
          (key) =>
            availibleCRMs[key] && (
              <NavItem
                key={key}
                active={activeTab === key}
                onClick={() => setActiveTab(key)}
              >
                <NavLink active={activeTab === key}>
                  {availibleCRMs[key]?.label}
                </NavLink>
              </NavItem>
            )
        )
        .concat(
          <NavItem>
            <NavLink
              onClick={() => setActiveTab('email')}
              active={activeTab === 'email'}
            >
              Email
            </NavLink>
          </NavItem>
        )
        .concat(
          apiDestinations.map((d) => (
            <NavItem
              key={d.destID}
              active={activeTab === d.destID}
              onClick={() => setActiveTab(d.destID)}
            >
              <NavLink active={activeTab === d.destID}>
                {d.destinationName}
              </NavLink>
            </NavItem>
          )),
          flags.allowCustomAPIs && isMe && (
            <NavItem
              key="custom"
              active={activeTab === 'custom'}
              onClick={() => setActiveTab('custom')}
            >
              <NavLink active={activeTab === 'custom'}>
                <FaPlus />
              </NavLink>
            </NavItem>
          )
        )
        .filter(Boolean),
    [availibleCRMs, activeTab, apiDestinations, flags.allowCustomAPIs, isMe]
  );
  console.log(availibleCRMs);
  const crmFields = useMemo(() => {
    const crms = Object.keys(availibleCRMs || {}).map((key) => {
      if (!availibleCRMs[key]) {
        return null;
      }
      switch (key) {
        case 'vanillaSoftDirect':
          return (
            <TabPane key={key} tabId={key}>
              <VanillaSoftCRMProfile
                isMe={isMe}
                allowSelfEdit={currentUser.flags.allowSelfEdit}
                canEdit={canEdit}
                crmID={key}
                //   onChange={crmUpdated}
                {...availibleCRMs[key]}
                {...vanillaSoftDirect}
              />
            </TabPane>
          );
        case 'iSalesCRM':
          return (
            <TabPane key={key} tabId={key}>
              <ISalesCRMProfile
                canEdit={canEdit}
                crmID={key}
                allowSelfEdit={currentUser.flags.allowSelfEdit}
                //   onChange={crmUpdated}
                {...availibleCRMs[key]}
                {...iSalesCRM}
              />
            </TabPane>
          );
        case 'textdrip':
          return (
            <TabPane key={key} tabId={key}>
              <TextdripProfile {...availibleCRMs[key]} {...textdrip} />
            </TabPane>
          );
        case 'gohighlevel':
          return (
            <TabPane key={key} tabId={key}>
              <GoHighLevelProfile {...availibleCRMs[key]} {...gohighlevel} />
            </TabPane>
          );
        case 'onlysales':
          return (
            <TabPane key={key} tabId={key}>
              <OnlySalesProfile
                canEdit
                crmID={key}
                allowSelfEdit={currentUser.flags.allowSelfEdit}
                //   onChange={crmUpdated}
                {...availibleCRMs[key]}
                {...onlysales}
              />
            </TabPane>
          );
        default:
          return (
            <TabPane tabId={key}>
              <h1>{key}</h1>
            </TabPane>
          );
      }
    });

    return crms
      .concat(
        <TabPane key="email" tabId="email">
          <EmailDestinationProfile />
        </TabPane>
      )
      .concat(
        apiDestinations.map((d) => (
          <TabPane key={d.destID} tabId={d.destID}>
            <CustomAPIProfile destinationID={d.destID} />
          </TabPane>
        )),
        flags.allowCustomAPIs && isMe && (
          <TabPane key="custom" tabId="custom">
            <CustomAPIProfile destinationID="new" />
          </TabPane>
        )
      )
      .filter(Boolean);
  }, [
    availibleCRMs,
    apiDestinations,
    flags.allowCustomAPIs,
    isMe,
    currentUser.flags.allowSelfEdit,
    canEdit,
    vanillaSoftDirect,
    iSalesCRM,
    textdrip,
    gohighlevel,
    onlysales,
  ]);

  return (
    <Card className="mb-3">
      <CardHeader className="d-flex justify-content-between">
        <CardTitle tag="h5" className="py-2 h4 mb-0">
          Integrations
        </CardTitle>
        {currentUser.flags.allowRegisterForOutboundTransfers ? (
          <a
            href={`https://dorneregion.typeform.com/register#username=${currentUser.email}&agent_id=${currentUser.agentID}&network=${currentUser.networkID}&first_name=${currentUser.firstName}&last_name=${currentUser.lastName}`}
            rel="noopener noreferrer"
            target="_blank"
            className="ml-4"
          >
            REGISTER OUTBOUND LIVE TRANSFERS
          </a>
        ) : null}
      </CardHeader>
      <CardBody>
        <Nav tabs>{crmTabs}</Nav>
        <TabContent activeTab={activeTab} className="mt-3">
          {crmFields}
        </TabContent>
        {crmTabs.length === 0 && !loadingDestinations && (
          <div className="py-3 text-center">
            Please reach out to your admin for custom integrations.
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default CRMProfiles;
