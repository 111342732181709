import gql from 'graphql-tag';

export default gql`
  mutation addOrUpdateCard($cardId: String, $corpID: String) {
    accountAddOrUpdateCard(cardId: $cardId, corpID: $corpID) {
      id
      last4
      brand
      exp_month
      exp_year
      address_zip
      customer
    }
  }
`;
