import gql from 'graphql-tag';

export default gql`
  mutation updateLastResult($input: UpdateLastResultInputObject) {
    updateLastResult(input: $input) {
      leadID
      lastResult
    }
  }
`;
