import React, { FC, useEffect } from 'react';
import { Form } from '@availity/form';
import omit from 'lodash.omit';
import { useNotifications, usePageLoader, useUser } from '@/hooks';
import { Button, Container, Row, Spinner } from 'reactstrap';
import { useMutation } from '@apollo/react-hooks';
import { uuid } from 'uuidv4';
import { updateUserProfile } from '@/graphql/mutations';
import { AgentFragment } from '@/graphql/fragments';
import { useHistory } from 'react-router-dom';
import { Vars } from '@/utils';

const GoHighLevelAuth: FC = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const code = queryParams.get('code');

  const { user } = useUser();
  const history = useHistory();
  const { complete } = usePageLoader();
  const { create: createNotification } = useNotifications();

  const [updateProfile] = useMutation(updateUserProfile, {
    onCompleted: () => {
      createNotification({
        message: 'Successfully Authorized Go High Level.',
        type: 'alert',
        action: 'MESSAGE',
        color: 'success',
      });
      complete();
      history.push('/profile/me');
    },
    update(cache, { data: { accountUpdate } }) {
      cache.writeFragment({
        id: accountUpdate.id,
        fragment: AgentFragment,
        data: accountUpdate,
      });
    },
    onError: (error) => {
      createNotification({
        message: error.message.replace('GraphQL error:', ''),
        type: 'alert',
        action: 'MESSAGE',
        color: 'danger',
      });

      complete();
    },
  });

  useEffect(() => {
    console.log(user!.crmProfiles);
    if (code) {
      updateProfile({
        variables: {
          input: {
            agentID: user.agentID,
            crmProfiles: {
              vanillaSoftDirect: {
                ...user!.crmProfiles!.vanillaSoftDirect,
                label: 'VanillaSoft',
                profiles: user!.crmProfiles!.vanillaSoftDirect?.profiles?.map(
                  (p) => ({
                    ...omit(p, ['__typename', 'profileID']),
                    id: p.profileID || p.id,
                  })
                ),
              },
              iSalesCRM: {
                ...user!.crmProfiles!.iSalesCRM,
                label: 'iSalesCRM',
                profiles: user!.crmProfiles!.iSalesCRM?.profiles?.map((p) => ({
                  ...omit(p, ['__typename', 'profileID']),
                  id: p.profileID || p.id,
                })),
              },
              textdrip: {
                ...user!.crmProfiles!.textdrip,
                label: 'Textdrip',
                profiles: user!.crmProfiles!.textdrip?.profiles?.map((p) => ({
                  ...omit(p, ['__typename', 'profileID']),
                  id: p.profileID || p.id,
                })),
              },
              gohighlevel: {
                code: code?.trim(),
                redirect_uri: window.location
                  .toString()
                  .replace(window.location.search, ''),
                // email: email?.trim(),
                label: 'Go High Level',
                profiles: user!.crmProfiles!.gohighlevel?.profiles?.map(
                  (p) => ({
                    ...omit(p, ['__typename', 'profileID']),
                    id: p.profileID || p.id,
                  })
                ),
              },
              onlysales: {
                ...user!.crmProfiles!.onlysales,
                label: 'OnlySales',
                profiles: user!.crmProfiles.onlysales?.profiles?.map((p) => ({
                  ...omit(p, ['__typename', 'profileID']),
                  id: p.profileID || p.id,
                  description: p?.description.trim(),
                  crmFields: {
                    integrationLink: p?.crmFields.integrationLink.trim(),
                    authToken: p?.crmFields.authToken.trim(),
                  },
                })),
              },
            },
          },
        },
      });
    }
  }, []);

  const options = {
    requestType: 'code',
    redirectUri: window.location.origin,
    clientId: Vars.goHighLevelClientId,
    scopes: [
      'locations.readonly',
      'users.readonly',
      'contacts.write',
      // "calendars.readonly",
      // "campaigns.readonly",
      // "contacts.readonly",
      // "calendars/events.write",
      // "calendars/events.readyonly"
    ],
  };

  const authUrl = `https://marketplace.gohighlevel.com/oauth/chooselocation?response_type=${
    options.requestType
  }&redirect_uri=${options.redirectUri}&client_id=${
    options.clientId
  }&scope=${options.scopes.join(' ')}`;

  return (
    <>
      {code ? (
        <Spinner color="primary" className="h4 mx-auto" />
      ) : (
        <>
          <Row className="d-flex justify-content-center">
            <h3>Missing Go Highlevel API Token</h3>
          </Row>
          <Row className="d-flex justify-content-center">
            <h4>Please reauthorize Go High Level.</h4>
          </Row>
          <Row className="d-flex justify-content-center">
            <Button
              color="primary"
              onClick={() => {
                window.location.replace(authUrl);
              }}
            >
              Back
            </Button>
          </Row>
        </>
      )}
    </>
  );
};

export default GoHighLevelAuth;
