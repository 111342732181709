/* eslint-disable react/no-array-index-key */
import React, { useEffect, useMemo, useState } from 'react';
import {
  Table,
  ButtonGroup,
  Button,
  Row,
  Col,
  Label,
  FormText,
  FormGroup,
} from 'reactstrap';
import classNames from 'classnames';
import chunk from 'lodash.chunk';
import { FaHashtag, FaFlask, FaTrash, FaGgCircle } from 'react-icons/fa';
import { Form, Field, Input } from '@availity/form';
import { FieldArray } from 'formik';
import omit from 'lodash.omit';
import { useMutation } from '@apollo/react-hooks';
import { updateUserProfile } from '@/graphql/mutations';
import { AgentFragment } from '@/graphql/fragments';
import { usePageLoader, useNotifications, useISalesExtension } from '@/hooks';
import * as yup from 'yup';
import { uuid } from 'uuidv4';
import useProfile from '../../useProfile';

interface SalesCRMProfileProps {
  crmID: string;
  canEdit: boolean;
  allowSelfEdit: boolean;
  profiles: any[];
  fields: any;
  iSalesUserName: string;
}

const ISalesCRMProfile: React.FC<SalesCRMProfileProps> = ({
  crmID,
  canEdit,
  allowSelfEdit,
  profiles = [],
  fields,
  iSalesUserName,
}) => {
  const { complete, start } = usePageLoader();
  const { data: user } = useProfile();
  const { create: createNotification } = useNotifications();
  const { iSalesKeys = [], isSynced, clearSync } = useISalesExtension();
  const [testValidID, setTestValidID] = useState({ token: '', SID: '' });

  const [updateProfile, { loading: updating }] = useMutation(
    updateUserProfile,
    {
      onCompleted: () => {
        clearSync();
        createNotification({
          message: 'Successfully updated Profile.',
          type: 'alert',
          action: 'MESSAGE',
          color: 'success',
        });
        complete();
      },
      update(cache, { data: { accountUpdate } }) {
        cache.writeFragment({
          id: accountUpdate.id,
          fragment: AgentFragment,
          data: accountUpdate,
        });
      },
      onError: (error) => {
        createNotification({
          message: error.message.replace('GraphQL error:', ''),
          type: 'alert',
          action: 'MESSAGE',
          color: 'danger',
        });

        complete();
      },
    }
  );

  return (
    <Form
      validationSchema={yup.object().shape({
        iSalesUserName: yup.string(),
        profiles: yup.array().of(
          yup
            .object()
            .shape({
              crmFields: yup.object().shape(
                fields.reduce((acc: any, field: any) => {
                  acc[field.name] = yup.string().isRequired(field.required);

                  return acc;
                }, {} as any)
              ),
            })
            .test(
              'duplicate-field',
              'Token and SID already in use.',
              (
                val: any,
                ctx: yup.TestContext<Record<string, any>>
              ): boolean => {
                const uniqueId = Object.values(val.crmFields).join('@');
                return !ctx.parent.find((profile) => {
                  if (profile.id === val.id) {
                    return false;
                  }
                  const profileID = Object.values(profile.crmFields).join('@');
                  return profileID === uniqueId;
                });
              }
            )
        ),
      })}
      initialValues={{
        profiles: profiles || [],
        iSalesUserName: iSalesUserName || '',
      }}
      onSubmit={(values) => {
        updateProfile({
          variables: {
            input: {
              agentID: user.agentID,
              crmProfiles: {
                vanillaSoftDirect: {
                  ...user!.crmProfiles!.vanillaSoftDirect,
                  label: 'VanillaSoft',
                  profiles: user!.crmProfiles!.vanillaSoftDirect?.profiles?.map(
                    (p) => ({
                      ...omit(p, ['__typename', 'profileID']),
                      id: p.profileID || p.id,
                    })
                  ),
                },
                textdrip: {
                  ...user!.crmProfiles!.textdrip,
                  label: 'Textdrip',
                  profiles: user!.crmProfiles!.textdrip?.profiles?.map((p) => ({
                    ...omit(p, ['__typename', 'profileID']),
                    id: p.profileID || p.id,
                  })),
                },
                gohighlevel: {
                  ...user!.crmProfiles!.gohighlevel,
                  label: 'Go High Level',
                  profiles: user!.crmProfiles!.gohighlevel?.profiles?.map(
                    (p) => ({
                      ...omit(p, ['__typename', 'profileID']),
                      id: p.profileID || p.id,
                    })
                  ),
                },
                iSalesCRM: {
                  ...user!.crmProfiles!.iSalesCRM,
                  iSalesUserName: values.iSalesUserName.trim(),
                  label: 'ISalesCRM',
                  profiles: values.profiles?.map((p) => ({
                    ...omit(p, ['__typename', 'profileID', 'synced']),
                    id: p.profileID || p.id,
                    description: p?.description.trim(),
                    crmFields: {
                      authToken: p?.crmFields.authToken.trim(),
                      sid: p?.crmFields.sid.trim(),
                    },
                  })),
                },
                onlysales: {
                  ...user!.crmProfiles!.onlysales,
                  label: 'OnlySales',
                  profiles: user!.crmProfiles.onlysales?.profiles?.map((p) => ({
                    ...omit(p, ['__typename', 'profileID']),
                    id: p.profileID || p.id,
                    description: p?.description.trim(),
                    crmFields: {
                      integrationLink: p?.crmFields.integrationLink.trim(),
                      authToken: p?.crmFields.authToken.trim(),
                    },
                  })),
                  // profiles: values.profilesOnlySales.map((p) => ({
                  //   ...omit(p, ['__typename', 'profileID']),
                  //   id: p.profileID || p.id,
                  // })),
                },
              },
            },
          },
        });
        start();
      }}
    >
      {({ values, setFieldValue, resetForm, errors }) => {
        const newISalesSynced = useMemo(
          () =>
            iSalesKeys
              .filter(
                (k: any) =>
                  !profiles.some(
                    (p) =>
                      p.crmFields.authToken === k.keys.authToken &&
                      p.crmFields.sid === k.keys.sid
                  )
              )
              .map((v: any) => {
                const id = `iSalesCRM-${uuid()}`;
                return {
                  id,
                  profileID: id,
                  description: v.name,
                  crmFields: v.keys,
                  synced: true,
                };
              }),
          [iSalesKeys, values.profiles]
        );

        useEffect(() => {
          resetForm();
        }, [user.agentID]);

        useEffect(() => {
          if (newISalesSynced && newISalesSynced.length > 0) {
            setFieldValue('profiles', [...values.profiles, ...newISalesSynced]);
          }
        }, [newISalesSynced.length]);

        return (
          <div>
            <div className="mb-3">
              <FormGroup>
                <Label>ISalesCRM Email</Label>
                <Input
                  type="email"
                  disabled={!allowSelfEdit}
                  name="iSalesUserName"
                />
                <FormText>
                  Enter the email you use to log into ISalesCRM
                </FormText>
              </FormGroup>

              {newISalesSynced.length > 0 ? (
                <span className="text-success">
                  {' '}
                  <FaGgCircle className="mb-2" /> {newISalesSynced.length} new
                  connections synced! Press save changes to complete!{' '}
                </span>
              ) : null}
              {isSynced &&
              newISalesSynced.length === 0 &&
              iSalesKeys.length > 0 ? (
                <span className="text-warning">
                  {' '}
                  <FaGgCircle className="mb-2" /> No new connections found to
                  sync!{' '}
                </span>
              ) : null}
              {isSynced && iSalesKeys.length === 0 ? (
                <span className="text-warning">
                  {' '}
                  <FaGgCircle className="mb-2" /> No vendor connections found in
                  ISalesCRM. Please visit our{' '}
                  <a
                    href="https://docs.ushamarketplace.com/article/71-add-marketplace-leads-to-isales-account-marketplace"
                    target="_blank"
                  >
                    knowledge base
                  </a>{' '}
                  with the steps to setup your vendor first in ISalesCRM.
                </span>
              ) : null}
            </div>
            <Table className="border-bottom border-secondary">
              <thead>
                <tr>
                  <th colSpan={2} className="border-0">
                    Profiles
                  </th>
                </tr>
              </thead>
              <tbody>
                <FieldArray
                  name="profiles"
                  render={(arrayHelpers) => {
                    return (
                      <>
                        {values.profiles?.map((profile, profileIdx) => (
                          <tr key={profile.id}>
                            <td className="d-flex flex-column">
                              <span>
                                <FaHashtag className="mb-2" />
                                <h4 className="d-inline">{profileIdx + 1}</h4>
                              </span>
                              {canEdit && (
                                <div>
                                  <ButtonGroup vertical>
                                    <Button
                                      color="info"
                                      disabled
                                      title="Test profile (COMING SOON)"
                                    >
                                      <FaFlask />
                                    </Button>
                                    <Button
                                      color="danger"
                                      title="Delete Profile"
                                      // disabled
                                      id={`delete-profile-${profileIdx}`}
                                      onClick={() =>
                                        arrayHelpers.remove(profileIdx)
                                      }
                                    >
                                      <FaTrash />
                                    </Button>
                                  </ButtonGroup>
                                </div>
                              )}
                            </td>
                            <td>
                              <Row noGutters>
                                <Col xs={12}>
                                  <Field
                                    label={
                                      profile.synced ? (
                                        <>
                                          <span>Description</span>
                                          <span className="text-success d-flex align-items-center">
                                            <FaGgCircle className="mr-1" />{' '}
                                            Synced
                                          </span>
                                        </>
                                      ) : (
                                        'Description'
                                      )
                                    }
                                    labelClass="d-flex justify-content-between"
                                    placeholder="A descriptive name for this profile"
                                    name={`profiles.${profileIdx}.description`}
                                    disabled={!canEdit}
                                  />
                                </Col>
                                {chunk(fields, 2).map((chunk: any) =>
                                  chunk.map((subChunk: any) => (
                                    <Col
                                      key={`profiles.${profileIdx}.crmFields.${subChunk.name}`}
                                      xs={chunk.length === 2 ? 6 : 12}
                                    >
                                      <Field
                                        label={subChunk.label}
                                        placeholder={subChunk.placeholder}
                                        type={subChunk.type}
                                        disabled={!canEdit}
                                        name={`profiles.${profileIdx}.crmFields.${subChunk.name}`}
                                      />
                                    </Col>
                                  ))
                                )}
                                <div className="text-danger">
                                  {typeof errors?.profiles?.[profileIdx] ===
                                  'string'
                                    ? errors.profiles[profileIdx]
                                    : undefined}
                                </div>
                              </Row>
                            </td>
                          </tr>
                        ))}
                        {canEdit && (
                          <tr>
                            <td colSpan={2}>
                              <Button
                                color="success"
                                className="w-100"
                                onClick={() =>
                                  arrayHelpers.push({
                                    crmFields: fields.reduce(
                                      (acc: any, field: any) => ({
                                        ...acc,
                                        [field.name]: '',
                                      }),
                                      {}
                                    ),
                                    id: `iSalesCRM-${uuid()}`,
                                  })
                                }
                              >
                                Add New Profile
                              </Button>
                            </td>
                          </tr>
                        )}
                      </>
                    );
                  }}
                />
              </tbody>
            </Table>
            <Button
              color="secondary"
              className={classNames({
                'd-none': !canEdit,
              })}
              type="reset"
            >
              Reset Changes
            </Button>
            <Button
              color="primary"
              className={classNames('float-right', {
                'd-none': !canEdit && !allowSelfEdit,
              })}
              disabled={updating}
              type="submit"
            >
              Save Changes
            </Button>
          </div>
        );
      }}
    </Form>
  );
};

export default ISalesCRMProfile;
