import gql from 'graphql-tag';
import VendorPartial from './vendorPartial';

const AgentFragment = gql`
  fragment AgentFragment on Agent {
    id
    __typename
    networkID
    agentID
    managerIDs
    agentApproved
    subID
    totalCredit
    contributedCredit
    sonicCredit
    leadBuyInAmount
    otherBrokerCredits {
      brokerName
      brokerID
      credits
    }
    sidelineCampaigns
    agentIDs
    callerReadyAccountStatus
    lastCallerReadyChargeResult
    callerReadyStatus
    lastCallerReadyCharge
    agentCallerReadyID
    autoFundSettings {
      threshold
      amount
      enabled
    }
    allowedVendors{
      ${VendorPartial}
    }
    networks {
      networkID
      networkName
      customCampaignTabName
    }
    network {
      corpID
      networkID
      networkName
      customCampaignTabName
      supportEmail
      showLastResults
      vendorLayerPrices {
        blockDuplicates
        blockDisconnected
        enhanceProfile
      }
      services {
        callerready {
          fee  {
            description
            amount
          }
          minimumAccountBalance
        }
      }
      agentDashboards {
         name
         code
         managerOnly
         #filters
      },
      refundPolicy
      allowedStates
      brokerID
      availibleCRMs {
        iSalesCRM {
          crmUrl
          attributesID
          teamRoutingAttribute
          label
          fields {
            required
            placeholder
            name
            type
            label
          }
        }
        vanillaSoftDirect {
          crmUrl
          attributesID
          teamRoutingAttribute
          label
          fields {
            required
            placeholder
            name
            type
            label
          }
        }
        textdrip {
          crmUrl
          attributesID
          label
          fields {
            required
            placeholder
            name
            type
            label
          }
        }
        gohighlevel {
          crmUrl
          attributesID
          label
          fields {
            required
            placeholder
            name
            type
            label
          }
        }
        onlysales {
          crmUrl
          attributesID
          label
          fields {
            required
            placeholder
            name
            type
            label
          }
        }
      }
    }
    flags {
      allowManagerDashboard
      allowCallerReady
      allowMarketplaceBrokering
      allowCrossNetworkTransfers
      allowMyAgents
      allowBeta
      allowVendor
      allowVendors
      allowSelfEdit
      canDeliverDuplicateLeads
      allowBrokerRejects
      allowProportionalDestinations
      allowCreditSummary
      allowCustomAPIs
      allowBulkSales
      allowRegisterForOutboundTransfers
      allowSameDestination
      allowSideline
      allowMyAgentEmails
      hideCampaigns
    }
    agentCRMID
    corpID
    companyID
    mainGroupID
    mainGroupName
    email
    firstName
    lastName
    phone
    buyerID
    mainGroupID
    companyID
    agentCorpID
    userID
    phone
    team
    role
    destinations: crmProfilesAsDestinations {
      destID
      agentID
      label
      destinationType
      destination
      uiID
    }
    crmProfiles {
      vanillaSoftDirect {
        label
        crmTeamName
        crmUserID
        crmCampaign
        crmUrl
        profiles {
          id
          profileID
          description
          crmFields
          crmExcludeUserID
        }
      }
      iSalesCRM {
        crmToken
        crmUserID
        crmTeamName
        sid
        label
        iSalesUserName
        profiles {
          description
          id
          profileID
          crmFields
        }
      }
      textdrip {
        label
        apiToken
        email
        profiles {
          id
          profileID
          crmFields
          description
        }
      }
      gohighlevel {
        label
        code
        redirect_uri
        profiles {
          id
          profileID
          crmFields
          description
        }
      }
      onlysales {
        label
        authToken
        integrationLink
        profiles {
          id
          profileID
          crmFields
          description
        }
      }
    }
  }
`;

export default AgentFragment;
