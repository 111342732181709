/* eslint-disable react/no-array-index-key */
import React, { useEffect } from 'react';
import {
  Table,
  ButtonGroup,
  Button,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
import classNames from 'classnames';
import chunk from 'lodash.chunk';
import { FaHashtag, FaFlask, FaTrash, FaFont } from 'react-icons/fa';
import { Form, Field, Input, Feedback } from '@availity/form';
import { FieldArray } from 'formik';
import { uuid } from 'uuidv4';
import * as yup from 'yup';
import omit from 'lodash.omit';
import { useMutation } from '@apollo/react-hooks';
import { AgentFragment } from '@/graphql/fragments';
import updateUserProfile from '@/graphql/mutations/updateUserProfile';
import { useNotifications, usePageLoader } from '@/hooks';
import useProfile from '../../useProfile';

interface VanillaSoftCRMProfileProps {
  crmID: string;
  canEdit: boolean;
  allowSelfEdit: boolean;
  role: string;
  profiles: any[];
  fields: any;
  crmCampaign: string;
  isMe: boolean;
}

const VanillaSoftCRMProfile: React.SFC<VanillaSoftCRMProfileProps> = ({
  crmID,
  canEdit,
  allowSelfEdit,
  role,
  profiles = [],
  fields,
  crmCampaign,
  isMe,
}) => {
  const { complete, start } = usePageLoader();
  const { data: user } = useProfile();
  const { create: createNotification } = useNotifications();
  const [updateProfile, { loading: updating }] = useMutation(
    updateUserProfile,
    {
      onCompleted: () => {
        createNotification({
          message: 'Successfully updated Profile.',
          type: 'alert',
          action: 'MESSAGE',
          color: 'success',
        });
        complete();
      },
      update(cache, { data: { accountUpdate } }) {
        cache.writeFragment({
          id: accountUpdate.id,
          fragment: AgentFragment,
          data: accountUpdate,
        });
      },
      onError: (error) => {
        createNotification({
          message: error.message.replace('GraphQL error:', ''),
          type: 'alert',
          action: 'MESSAGE',
          color: 'danger',
        });
        complete();
      },
    }
  );

  return (
    <Form
      validationSchema={yup.object().shape({
        crmCampaign: yup.string(),
        profiles: yup.array().of(
          yup
            .object()
            .shape({
              crmFields: yup.object().shape(
                fields.reduce((acc: any, field: any) => {
                  acc[field.name] = yup.string().isRequired(field.required);

                  return acc;
                }, {} as any)
              ),
            })
            .test(
              'duplicate-field',
              'User ID and Team already in use.',
              (
                val: any,
                ctx: yup.TestContext<Record<string, any>>
              ): boolean => {
                const uniqueId = Object.values(val.crmFields).join('@');
                return !ctx.parent.find((profile) => {
                  if (profile.id === val.id) {
                    return false;
                  }
                  const profileID = Object.values(profile.crmFields).join('@');
                  return profileID === uniqueId;
                });
              }
            )
        ),
      })}
      initialValues={{
        profiles: profiles || [],
        crmCampaign: crmCampaign || '',
      }}
      onSubmit={(values) => {
        updateProfile({
          variables: {
            input: {
              agentID: user.agentID,
              crmProfiles: {
                iSalesCRM: {
                  ...user!.crmProfiles!.iSalesCRM,
                  label: 'iSalesCRM',
                  profiles: user!.crmProfiles!.iSalesCRM?.profiles?.map(
                    (p) => ({
                      ...omit(p, ['__typename', 'profileID']),
                      id: p.profileID || p.id,
                    })
                  ),
                },
                textdrip: {
                  ...user!.crmProfiles!.textdrip,
                  label: 'Textdrip',
                  profiles: user!.crmProfiles!.textdrip?.profiles?.map((p) => ({
                    ...omit(p, ['__typename', 'profileID']),
                    id: p.profileID || p.id,
                  })),
                },
                gohighlevel: {
                  ...user!.crmProfiles!.gohighlevel,
                  label: 'Go High Level',
                  profiles: user!.crmProfiles!.gohighlevel?.profiles?.map(
                    (p) => ({
                      ...omit(p, ['__typename', 'profileID']),
                      id: p.profileID || p.id,
                    })
                  ),
                },
                vanillaSoftDirect: {
                  ...user!.crmProfiles!.vanillaSoftDirect,
                  crmCampaign: values.crmCampaign,
                  label: 'VanillaSoft',
                  profiles: values.profiles?.map((p) => ({
                    ...omit(p, ['__typename', 'profileID']),
                    id: p.profileID || p.id,
                    description: p?.description.trim(),
                    crmFields: {
                      team: p?.crmFields?.team.trim(),
                      agentCRMID: p?.crmFields?.agentCRMID.trim(),
                    },
                  })),
                },
                onlysales: {
                  ...user!.crmProfiles!.onlysales,
                  label: 'OnlySales',
                  profiles: user!.crmProfiles.onlysales?.profiles?.map((p) => ({
                    ...omit(p, ['__typename', 'profileID']),
                    id: p.profileID || p.id,
                    description: p?.description.trim(),
                    crmFields: {
                      integrationLink: p?.crmFields.integrationLink.trim(),
                      authToken: p?.crmFields.authToken.trim(),
                    },
                  })),
                  // profiles: values.profilesOnlySales.map((p) => ({
                  //   ...omit(p, ['__typename', 'profileID']),
                  //   id: p.profileID || p.id,
                  // })),
                },
              },
            },
          },
        });
        start();
      }}
    >
      {({ values, resetForm, errors }) => {
        useEffect(() => {
          resetForm();
        }, [user.agentID]);

        return (
          <div>
            <div className="mb-3">
              <InputGroup>
                <InputGroupAddon addonType="prepend">Campaign</InputGroupAddon>
                <Input
                  name="crmCampaign"
                  type="text"
                  disabled={!canEdit}
                  placeholder="Jon Smith Team"
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText>
                    <FaFont title="text" />
                  </InputGroupText>
                </InputGroupAddon>
                <Feedback name="crmCampaign" />
              </InputGroup>
            </div>
            <Table className="border-bottom border-secondary">
              <thead>
                <tr>
                  <th colSpan={2} className="border-0">
                    Profiles
                  </th>
                </tr>
              </thead>
              <tbody>
                <FieldArray
                  name="profiles"
                  render={(arrayHelpers) => (
                    <>
                      {values.profiles?.map((profile, profileIdx) => (
                        <tr key={profile.id}>
                          <td className="d-flex flex-column">
                            <span>
                              <FaHashtag className="mb-2" />
                              <h4 className="d-inline">{profileIdx + 1}</h4>
                            </span>
                            {canEdit && (
                              <div>
                                <ButtonGroup vertical>
                                  <Button
                                    color="info"
                                    disabled
                                    title="Test profile (COMING SOON)"
                                  >
                                    <FaFlask />
                                  </Button>
                                  <Button
                                    color="danger"
                                    id={`delete-profile-${profileIdx}`}
                                    title="Delete Profile"
                                    // disabled
                                    onClick={() =>
                                      arrayHelpers.remove(profileIdx)
                                    }
                                  >
                                    <FaTrash />
                                  </Button>
                                </ButtonGroup>
                              </div>
                            )}
                          </td>
                          <td>
                            <Row noGutters>
                              <Col xs={12}>
                                <Field
                                  label="Description"
                                  placeholder="A descriptive name for this profile"
                                  name={`profiles.${profileIdx}.description`}
                                  disabled={!canEdit}
                                />
                              </Col>
                              {chunk(fields, 2).map((chunk: any) =>
                                chunk.map((subChunk: any) => {
                                  return (
                                    <Col
                                      key={`profiles.${profileIdx}.crmFields.${subChunk.name}`}
                                      xs={chunk.length === 2 ? 6 : 12}
                                    >
                                      <Field
                                        label={subChunk.label}
                                        placeholder={subChunk.placeholder}
                                        type={subChunk.type}
                                        disabled={!canEdit}
                                        name={`profiles.${profileIdx}.crmFields.${subChunk.name}`}
                                      />
                                    </Col>
                                  );
                                })
                              )}
                              <div className="text-danger">
                                {typeof errors?.profiles?.[profileIdx] ===
                                'string'
                                  ? errors.profiles[profileIdx]
                                  : undefined}
                              </div>
                            </Row>
                          </td>
                        </tr>
                      ))}
                      {canEdit && (
                        <tr>
                          <td colSpan={2}>
                            <Button
                              color="success"
                              className="w-100"
                              onClick={() =>
                                arrayHelpers.push({
                                  crmExcludeUserID: false,
                                  crmFields: fields.reduce(
                                    (acc: any, field: any) => ({
                                      ...acc,
                                      [field.name]: '',
                                    }),
                                    {}
                                  ),
                                  id: `vanillaSoftDirect-${uuid()}`, // TODO - maybe just use uuid here
                                })
                              }
                            >
                              Add New Profile
                            </Button>
                          </td>
                        </tr>
                      )}
                    </>
                  )}
                />
              </tbody>
            </Table>
            <Button
              color="secondary"
              className={classNames({
                'd-none': !canEdit,
              })}
              type="reset"
            >
              Reset Changes
            </Button>

            <Button
              color="primary"
              className={classNames('float-right', {
                'd-none': !canEdit && !allowSelfEdit,
              })}
              type="submit"
              disabled={updating}
            >
              Save Changes
            </Button>
          </div>
        );
      }}
    </Form>
  );
};

export default VanillaSoftCRMProfile;
