/* eslint-disable no-undef */
import React from 'react';
import {
  Form,
  Card,
  CardTitle,
  CardBody,
  CardHeader,
  Button as RsButton,
  Container,
} from 'reactstrap';
import { Field } from '@availity/form';
import { SelectField } from '@availity/select';
import { useNotifications, useUser } from '@/hooks';
import Button from '@gopro16/button';
import { Formik } from 'formik';
import * as yup from 'yup';
import { RouteComponentProps } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { createSupportTicketMutation } from '@/graphql/mutations';

const supportTypes = [
  { label: 'Report Bug', value: 'bug' },
  { label: 'Chrome Extension', value: 'chrome extension' },
  { label: 'Contribute Idea', value: 'idea' },
  { label: 'Support Request', value: 'support' },
  { label: 'Other', value: 'other' },
];

const Support: React.FC<RouteComponentProps> = ({ history }) => {
  const { create: createNotification } = useNotifications();
  const {
    user: {
      corpID,
      network: { supportEmail },
    },
  } = useUser();

  const supportLink = (): string => {
    if (corpID === 'usha') {
      return 'https://knowledge.ushamarketplace.com/supportcenter';
    }
    if (corpID === 'sonic') {
      return 'https://docs.sonicleads.com/';
    }
    if (corpID === 'americafirst') {
      return `https://7313950.hs-sites.com/america-first-leads-resource-center`;
    }
    if (corpID === 'leadtopia') {
      return `https://7313950.hs-sites.com/leadtopia-resource-center`;
    }
    return '';
  };

  const [
    createSupportTicket,
    { loading: updating, called, error },
  ] = useMutation(createSupportTicketMutation, {
    onError: () =>
      createNotification({
        message: 'Something went wrong',
        type: 'alert',
        action: 'MESSAGE',
        color: 'danger',
      }),
  });

  return (
    <Container style={{ maxWidth: 500 }}>
      {corpID !== 'leadtopia' && corpID !== 'americafirst' ? (
        <>
          <Formik
            initialValues={{
              supportType: undefined,
              description: '',
            }}
            onSubmit={async (values: any) => {
              createSupportTicket({
                variables: {
                  description: values.description,
                  supportType: values.supportType,
                },
              });
            }}
            validationSchema={yup.object().shape({
              supportType: yup.string().required('Support Type is required.'),
              description: yup.string().required('Description is required.'),
            })}
          >
            {({ handleSubmit, handleReset }) => (
              <Card>
                <CardHeader>
                  <CardTitle tag="h5" className="py-2 h4 mb-0">
                    {!updating && called && !error
                      ? 'Thanks for your Feedback'
                      : 'Reach out to Us'}
                  </CardTitle>
                </CardHeader>
                <CardBody className="d-block">
                  {!updating && called && !error ? (
                    <h5 className="text-center">
                      We will reach out to you as soon as possible.
                      <div className="mt-3">
                        <RsButton
                          onClick={() => history.push('/')}
                          color="primary"
                        >
                          Back To Home
                        </RsButton>
                      </div>
                    </h5>
                  ) : (
                    <Form onSubmit={handleSubmit} onReset={handleReset}>
                      <SelectField
                        name="supportType"
                        options={supportTypes}
                        label="Support Type"
                      />
                      <Field
                        label="Description"
                        name="description"
                        rows="5"
                        placeholder="Please provide a detailed description of your issue. 
                  For campaign issues, please include the campaign name and spending period description. 
                  If billing issues, please include transaction amount and date."
                        type="textarea"
                        required
                      />
                      <Button
                        loading={updating}
                        disabled={updating}
                        color="primary"
                        block
                        type="submit"
                      >
                        Submit
                      </Button>
                    </Form>
                  )}
                </CardBody>
              </Card>
            )}
          </Formik>
          <div className="text-center mt-3">
            You can also visit our{' '}
            <a href={supportLink()} target="_blank" rel="noreferrer">
              Help Docs
            </a>{' '}
            for additional support.
          </div>
        </>
      ) : (
        <>
          <div className="d-flex flex-column align-items-center h5 w-100">
            <p>All support questions can be handled by sending an email to:</p>
            <a href={`mailto:${supportEmail}`}>{supportEmail}</a>
          </div>
          <div className="text-center mt-3">
            You can also visit our{' '}
            <a href={supportLink()} target="_blank" rel="noreferrer">
              Help Docs
            </a>{' '}
            for additional support.
          </div>
        </>
      )}
    </Container>
  );
};

export default Support;
