import React from 'react';
import Switch from 'react-switch';
import { FaSync } from 'react-icons/fa';
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
} from 'reactstrap';
import { Form } from '@availity/form';
import { Vars, formatCurrencyForDisplay } from '@/utils';
import * as yup from 'yup';
import { useMutation } from '@apollo/react-hooks';
import { currentAgentQuery } from '@/graphql/queries';
import { updateUserProfile } from '@/graphql/mutations';
import { useNotifications } from '@/hooks';
import { MoneyField } from '@/components';

import useProfile from '../../useProfile';

import CallerReady from './Components/CallerReady';

import Sideline from './Components/Sideline';

interface ServicesProps {
  // amount: number;
  // enabled: boolean;
  // threshold: number;
  viewLoading: boolean;
  agentID: string;
}

interface ServiceFee {
  description: string;
  amount: number;
}

interface Service {
  id: string;
  fee: ServiceFee;
  minimumAccountBalance: number;
}

const getService = (network: any, service: string): Service => {
  const { services = {} } = network;
  const temp = services[service] || {};
  return { ...temp, id: service };
};

const Services: React.FunctionComponent<ServicesProps> = ({
  // amount,
  // enabled,
  // threshold,
  viewLoading,
  agentID,
}) => {
  const { data: user } = useProfile();

  const { create: createNotification } = useNotifications();

  const [
    updateProfile,
    { loading: updating },
    // eslint-disable-next-line react-hooks/rules-of-hooks
  ] = useMutation(updateUserProfile, {
    update(cache, { data: { accountUpdate } }) {
      const { userCurrent, account } = cache.readQuery({
        query: currentAgentQuery,
      }) as any;

      cache.writeQuery({
        query: currentAgentQuery,
        data: {
          account,
          userCurrent: {
            ...userCurrent,
            callerReadyAccountStatus: accountUpdate.callerReadyAccountStatus,
          },
        },
      });
    },
    onCompleted: () => {
      createNotification({
        color: 'success',
        type: 'alert',
        action: 'MESSAGE',
        message: `Service's settings saved.`,
      });
    },
    onError: () =>
      createNotification({
        color: 'danger',
        type: 'alert',
        action: 'MESSAGE',
        message: `Failed to Service's settings. Please try again later.`,
      }),
  });

  return (
    <Form
      initialValues={{
        callerReadyAccountStatus: user.callerReadyAccountStatus || 'none',
        callerReadyStatus: user.callerReadyStatus || 'active',
      }}
      validationSchema={yup.object().shape({
        callerReadyAccountStatus: yup.string(),
        callerReadyStatus: yup.string(),
      })}
      onSubmit={(values) => {
        updateProfile({
          variables: {
            input: {
              agentID,
              callerReadyAccountStatus: values.callerReadyAccountStatus,
              callerReadyStatus: user.callerReadyStatus || 'active',
            },
          },
        });
      }}
    >
      {({ setFieldValue, values, handleReset }) => (
        <>
          <Card className="mt-2">
            <CardHeader className="d-flex justify-content-between">
              <CardTitle
                tag="h5"
                className="py-2 w-100 h4 mb-0 d-flex justify-content-between"
              >
                <span>Services</span>
                {updating ? (
                  <FaSync className="loading-sync" size={24} />
                ) : (
                  <ButtonGroup>
                    <Button
                      disabled={viewLoading}
                      color="dark"
                      onClick={(e) => handleReset(e)}
                    >
                      Reset
                    </Button>
                    <Button
                      color="primary"
                      disabled={viewLoading}
                      type="submit"
                    >
                      Save
                    </Button>
                  </ButtonGroup>
                )}
              </CardTitle>
            </CardHeader>
            <CardBody>
              Services are charged directly to your card from your Marketplace
              account.
            </CardBody>
          </Card>
          <br />
          {user.flags.allowCallerReady ? (
            <CallerReady
              setFieldValue={setFieldValue}
              values={values}
              service={getService(user.network, 'callerready')}
              user={user}
            />
          ) : null}
          {user.flags.allowSideline ? (
            <Sideline
              // setFieldValue={setFieldValue}
              // values={values}
              // service={getService(user.network, 'callerready')}
              user={user}
            />
          ) : null}
        </>
      )}
    </Form>
  );
};

export default Services;
