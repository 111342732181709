import gql from 'graphql-tag';

export default gql`
  fragment PriceTierFragment on Tier {
    id
    __typename
    description
    allowedDestinationTypes
    price
    liveTransferPrice
    dynamicPrice
    priceTierID
    vertical
    name
    liveTransfers
    allowedFilters
    minPrice
    maxPrice
    agentCreditOnly
    bulkPurchase
    bulkPurchaseTimeout
    bulkPurchaseMax
    bulkPurchaseCustom
    tierDisclaimer
    priceBreakPoints {
      breakPoint
      breakPointPrice
    }
    bulkPurchaseMin
    requiredDestination
    bulkPurchaseOnly
    skipAvailableEstimate
  }
`;
