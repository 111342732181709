export const getHost = (): string => {
  const domain = window.location.hostname;

  if (domain.includes('localhost')) {
    return 'leadtopia';
  }

  if (window.location.href.includes('americafirstleads')) {
    return 'americafirstleads';
  }

  if (window.location.href.includes('leadtopia')) {
    return 'leadtopia';
  }

  return domain.split('.')[1];
};

export const getImageUrl = (imageName: string): string => {
  return `https://s3.amazonaws.com/docs.sonicwebdev.com/images/${getHost()}/${imageName}`;
};

export default getHost;
