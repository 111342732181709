/* eslint-disable react/no-array-index-key */
import React, { useEffect, useMemo, useState } from 'react';
import {
  Table,
  ButtonGroup,
  Button,
  Row,
  Col,
  Label,
  FormText,
  FormGroup,
} from 'reactstrap';
import classNames from 'classnames';
import chunk from 'lodash.chunk';
import { FaHashtag, FaFlask, FaTrash, FaGgCircle } from 'react-icons/fa';
import { Form, Field, Input } from '@availity/form';
import { FieldArray } from 'formik';
import omit from 'lodash.omit';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { updateUserProfile } from '@/graphql/mutations';
import { AgentFragment } from '@/graphql/fragments';
import { usePageLoader, useNotifications, useISalesExtension } from '@/hooks';
import * as yup from 'yup';
import { uuid } from 'uuidv4';
import useProfile from '../../useProfile';
import axios from 'axios';

interface SalesCRMProfileProps {
  crmID: string;
  canEdit: boolean;
  allowSelfEdit: boolean;
  profiles: any[];
  fields: any;
}

interface ErrorHandler {
  index: number;
  message: string;
}

const OnlySalesProfile: React.FC<SalesCRMProfileProps> = ({
  crmID,
  canEdit,
  allowSelfEdit,
  profiles = [],
  fields,
}) => {
  const { complete, start } = usePageLoader();
  const { data: user } = useProfile();
  const { create: createNotification } = useNotifications();
  const [listError, setListError] = useState<ErrorHandler[]>([]);

  const [updateProfile, { loading: updating }] = useMutation(
    updateUserProfile,
    {
      onCompleted: () => {
        createNotification({
          message: 'Successfully updated Profile.',
          type: 'alert',
          action: 'MESSAGE',
          color: 'success',
        });
        complete();
      },
      update(cache, { data: { accountUpdate } }) {
        cache.writeFragment({
          id: accountUpdate.id,
          fragment: AgentFragment,
          data: accountUpdate,
        });
      },
      onError: (error) => {
        createNotification({
          message: error.message.replace('GraphQL error:', ''),
          type: 'alert',
          action: 'MESSAGE',
          color: 'danger',
        });

        complete();
      },
    }
  );

  return (
    <Form
      validationSchema={yup.object().shape({
        profilesOnlySales: yup.array().of(
          yup
            .object()
            .shape({
              crmFields: yup.object().shape(
                fields.reduce((acc: any, field: any) => {
                  acc[field.name] = yup.string().isRequired(field.required);

                  return acc;
                }, {} as any)
              ),
            })
            .test(
              'duplicate-field',
              'Token and link already in use.',
              (
                val: any,
                ctx: yup.TestContext<Record<string, any>>
              ): boolean => {
                const uniqueId = Object.values(val.crmFields).join('@');
                return !ctx.parent.find((profile) => {
                  if (profile.id === val.id) {
                    return false;
                  }
                  const profileID = Object.values(profile.crmFields).join('@');
                  return profileID === uniqueId;
                });
              }
            )
        ),
      })}
      initialValues={{
        profilesOnlySales: profiles || [],
      }}
      onSubmit={(values) => {
        const newMap = values.profilesOnlySales.map((value, index) => {
          return {
            link: value.crmFields.integrationLink,
            token: value.crmFields.authToken,
            description: value.description,
          };
        });

        if (newMap.length === 0) {
          updateProfile({
            variables: {
              input: {
                agentID: user.agentID,
                crmProfiles: {
                  vanillaSoftDirect: {
                    ...user!.crmProfiles!.vanillaSoftDirect,
                    label: 'VanillaSoft',
                    profiles: user!.crmProfiles!.vanillaSoftDirect?.profiles?.map(
                      (p) => ({
                        ...omit(p, ['__typename', 'profileID']),
                        id: p.profileID || p.id,
                      })
                    ),
                  },
                  textdrip: {
                    ...user!.crmProfiles!.textdrip,
                    label: 'Textdrip',
                    profiles: user!.crmProfiles!.textdrip?.profiles?.map(
                      (p) => ({
                        ...omit(p, ['__typename', 'profileID']),
                        id: p.profileID || p.id,
                      })
                    ),
                  },
                  iSalesCRM: {
                    ...user!.crmProfiles!.iSalesCRM,
                    label: 'ISalesCRM',
                    profiles: user!.crmProfiles!.iSalesCRM?.profiles?.map(
                      (p) => ({
                        ...omit(p, ['__typename', 'profileID']),
                        id: p.profileID || p.id,
                      })
                    ),
                  },
                  onlysales: {
                    ...user!.crmProfiles!.onlySales,
                    label: 'OnlySales',
                    profiles: values.profilesOnlySales?.map((p) => ({
                      ...omit(p, ['__typename', 'profileID']),
                      id: p.profileID || p.id,
                      description: p?.description.trim(),
                      crmFields: {
                        integrationLink: p?.crmFields.integrationLink.trim(),
                        authToken: p?.crmFields.authToken.trim(),
                      },
                    })),
                    // profiles: values.profilesOnlySales.map((p) => ({
                    //   ...omit(p, ['__typename', 'profileID']),
                    //   id: p.profileID || p.id,
                    // })),
                  },
                },
              },
            },
          });
        }

        try {
          /* console.log(user);
          newMap.forEach(async (value) => {
            const response = await axios.post(
              value.link,
              {
                firstName: user.firstName,
                lastName: user.lastName,
                phoneNumber: user.phone,
                dateOfBirth: '11/24/1997',
                source: user.companyID,
                campaign: 'testLead',
                cost: '0',
              },
              { headers: { Authorization: `Bearer ${value.token}` } }
            );

            const { data, status } = response;
            if (data) { */
          console.log('again?');
          updateProfile({
            variables: {
              input: {
                agentID: user.agentID,
                crmProfiles: {
                  vanillaSoftDirect: {
                    ...user!.crmProfiles!.vanillaSoftDirect,
                    label: 'VanillaSoft',
                    profiles: user!.crmProfiles!.vanillaSoftDirect?.profiles?.map(
                      (p) => ({
                        ...omit(p, ['__typename', 'profileID']),
                        id: p.profileID || p.id,
                      })
                    ),
                  },
                  textdrip: {
                    ...user!.crmProfiles!.textdrip,
                    label: 'Textdrip',
                    profiles: user!.crmProfiles!.textdrip?.profiles?.map(
                      (p) => ({
                        ...omit(p, ['__typename', 'profileID']),
                        id: p.profileID || p.id,
                      })
                    ),
                  },
                  gohighlevel: {
                    ...user!.crmProfiles!.gohighlevel,
                    label: 'Go High Level',
                    profiles: user!.crmProfiles!.gohighlevel?.profiles?.map(
                      (p) => ({
                        ...omit(p, ['__typename', 'profileID']),
                        id: p.profileID || p.id,
                      })
                    ),
                  },
                  iSalesCRM: {
                    ...user!.crmProfiles!.iSalesCRM,
                    label: 'ISalesCRM',
                    profiles: user!.crmProfiles!.iSalesCRM?.profiles?.map(
                      (p) => ({
                        ...omit(p, ['__typename', 'profileID']),
                        id: p.profileID || p.id,
                      })
                    ),
                  },
                  onlysales: {
                    ...user!.crmProfiles!.onlysales,
                    label: 'OnlySales',
                    profiles: values.profilesOnlySales?.map((p) => ({
                      ...omit(p, ['__typename', 'profileID']),
                      id: p.profileID || p.id,
                      description: p?.description.trim(),
                      crmFields: {
                        integrationLink: p?.crmFields.integrationLink.trim(),
                        authToken: p?.crmFields.authToken.trim(),
                      },
                    })),
                    // profiles: values.profilesOnlySales.map((p) => ({
                    //   ...omit(p, ['__typename', 'profileID']),
                    //   id: p.profileID || p.id,
                    // })),
                  },
                },
              },
            },
          });
          /* } else {
              setListError([...listError, { index: 0, message: data.message }]);
            }
          }); */
        } catch (error) {
          setListError([...listError, { index: 0, message: error.message }]);
        }

        //
        start();
      }}
    >
      {({ values, setFieldValue, resetForm, errors }) => {
        const newOnlySale = useMemo(
          () =>
            values.profilesOnlySales.map((v: any) => {
              const id = `onlysales-${uuid()}`;
              return {
                id,
                profileID: id,
                description: v.name,
                crmFields: v.keys,
              };
            }),
          [values.profilesOnlySales]
        );

        useEffect(() => {
          resetForm();
        }, [user.agentID]);

        useEffect(() => {
          if (newOnlySale && newOnlySale.length > 0) {
            setFieldValue('profilesOnlySales', [...values.profilesOnlySales]);
          }
        }, [newOnlySale.length]);

        return (
          <div>
            <Table className="border-bottom border-secondary">
              <thead>
                <tr>
                  <th colSpan={2} className="border-0">
                    Profiles
                  </th>
                </tr>
              </thead>
              <tbody>
                <FieldArray
                  name="profilesOnlySales"
                  render={(arrayHelpers) => {
                    return (
                      <>
                        {values.profilesOnlySales?.map(
                          (profile, profileIdx) => (
                            <tr key={profile.id}>
                              <td className="d-flex flex-column">
                                <span>
                                  <FaHashtag className="mb-2" />
                                  <h4 className="d-inline">{profileIdx + 1}</h4>
                                </span>
                                {canEdit && (
                                  <div>
                                    <ButtonGroup vertical>
                                      <Button
                                        color="info"
                                        disabled
                                        title="Test profile (COMING SOON)"
                                      >
                                        <FaFlask />
                                      </Button>
                                      <Button
                                        color="danger"
                                        title="Delete Profile"
                                        // disabled
                                        id={`delete-profile-${profileIdx}`}
                                        onClick={() => {
                                          arrayHelpers.remove(profileIdx);
                                          // Clean list error by index
                                          setListError(
                                            listError.filter(
                                              (error) =>
                                                error.index !== profileIdx
                                            )
                                          );
                                        }}
                                      >
                                        <FaTrash />
                                      </Button>
                                    </ButtonGroup>
                                  </div>
                                )}
                              </td>
                              <td>
                                <Row noGutters>
                                  <Col xs={12}>
                                    <Field
                                      label={
                                        profile.synced ? (
                                          <>
                                            <span>Description</span>
                                            <span className="text-success d-flex align-items-center">
                                              <FaGgCircle className="mr-1" />{' '}
                                              Synced
                                            </span>
                                          </>
                                        ) : (
                                          'Description'
                                        )
                                      }
                                      labelClass="d-flex justify-content-between"
                                      placeholder="A descriptive name for this profile"
                                      name={`profilesOnlySales.${profileIdx}.description`}
                                      disabled={!canEdit}
                                    />
                                  </Col>
                                  {chunk(fields, 2).map((chunk: any) =>
                                    chunk.map((subChunk: any) => (
                                      <Col
                                        key={`profilesOnlySales.${profileIdx}.crmFields.${subChunk.name}`}
                                        xs={chunk.length === 2 ? 6 : 12}
                                      >
                                        <Field
                                          label={subChunk.label}
                                          placeholder={subChunk.placeholder}
                                          type={subChunk.type}
                                          disabled={!canEdit}
                                          name={`profilesOnlySales.${profileIdx}.crmFields.${subChunk.name}`}
                                        />
                                      </Col>
                                    ))
                                  )}
                                  <div className="text-danger">
                                    {typeof errors?.profilesOnlySales?.[
                                      profileIdx
                                    ] === 'string'
                                      ? errors.profilesOnlySales[profileIdx]
                                      : undefined}
                                  </div>
                                  {setListError.length > 0 &&
                                    listError.find(
                                      (error) => error.index === profileIdx
                                    ) && (
                                      <div className="text-danger">
                                        {
                                          listError.find(
                                            (error) =>
                                              error.index === profileIdx
                                          )?.message
                                        }
                                      </div>
                                    )}
                                </Row>
                              </td>
                            </tr>
                          )
                        )}
                        {canEdit && (
                          <tr>
                            <td colSpan={2}>
                              <Button
                                color="success"
                                className="w-100"
                                onClick={() => {
                                  const data = {
                                    crmFields: fields.reduce(
                                      (acc: any, field: any) => ({
                                        ...acc,
                                        [field.name]: '',
                                      }),
                                      {}
                                    ),
                                    id: `onlysales-${uuid()}`,
                                  };
                                  arrayHelpers.push(data);
                                }}
                              >
                                Add New Profile
                              </Button>
                            </td>
                          </tr>
                        )}
                      </>
                    );
                  }}
                />
              </tbody>
            </Table>
            <Button
              color="secondary"
              className={classNames({
                'd-none': !canEdit,
              })}
              type="reset"
            >
              Reset Changes
            </Button>
            <Button
              color="primary"
              className={classNames('float-right', {
                'd-none': !canEdit && !allowSelfEdit,
              })}
              disabled={updating}
              type="submit"
            >
              Save Changes
            </Button>
          </div>
        );
      }}
    </Form>
  );
};

export default OnlySalesProfile;
