import React from 'react';
import Switch from 'react-switch';
import { SelectField } from '@availity/select';
import { FaSync } from 'react-icons/fa';
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormText,
} from 'reactstrap';
import { Form } from '@availity/form';
import { Vars, formatCurrencyForDisplay } from '@/utils';
import * as yup from 'yup';
import { useMutation } from '@apollo/react-hooks';
import { currentAgentQuery } from '@/graphql/queries';
import { useNotifications } from '@/hooks';
import updateUserProfile from '@/graphql/mutations/updateUserProfile';
import { MoneyField } from '@/components';

interface LeadCommitmentProps {
  leadBuyInAmount: number;
  agentID: string;
  viewLoading: boolean;
}

const LeadCommitment: React.FunctionComponent<LeadCommitmentProps> = ({
  leadBuyInAmount = 0,
  agentID,
  options = [],
  viewLoading,
}) => {
  const { create: createNotification } = useNotifications();

  const [
    updateUser,
    { loading: updating },
    // eslint-disable-next-line react-hooks/rules-of-hooks
  ] = useMutation(updateUserProfile, {
    update(cache, { data: { accountUpdate } }) {
      const { userCurrent, account } = cache.readQuery({
        query: currentAgentQuery,
      }) as any;

      cache.writeQuery({
        query: currentAgentQuery,
        data: {
          account,
          userCurrent: {
            ...userCurrent,
            leadBuyInAmount: accountUpdate.leadBuyInAmount,
          },
        },
      });
    },
    onCompleted: () => {
      createNotification({
        color: 'success',
        type: 'alert',
        action: 'MESSAGE',
        message: 'Lead Buy In selection saved.',
      });
    },
    onError: () =>
      createNotification({
        color: 'danger',
        type: 'alert',
        action: 'MESSAGE',
        message: 'Failed to save settings. Please try again later.',
      }),
  });

  return (
    <Form
      initialValues={{
        leadBuyInAmount,
      }}
      // validationSchema={yup.object().shape({

      // })}
      onSubmit={(values) => {
        updateUser({
          variables: {
            input: {
              agentID,
              leadBuyInAmount: values.leadBuyInAmount,
            },
          },
        });
      }}
    >
      {({ setFieldValue, values, handleReset }) => (
        <Card className="mt-2">
          <CardHeader className="d-flex justify-content-between">
            <CardTitle
              tag="h5"
              className="py-2 w-100 h4 mb-0 d-flex justify-content-between"
            >
              <span>Lead Weekly Buy In Mondays at 9am EST</span>
              {updating ? (
                <FaSync className="loading-sync" size={24} />
              ) : (
                <ButtonGroup>
                  <Button
                    disabled={viewLoading}
                    color="dark"
                    onClick={(e) => handleReset(e)}
                  >
                    Reset
                  </Button>
                  <Button color="primary" disabled={viewLoading} type="submit">
                    Save
                  </Button>
                </ButtonGroup>
              )}
            </CardTitle>
          </CardHeader>
          <CardBody>
            <MoneyField name="leadBuyInAmount" minimum={0} size="md" />

            <FormText>Set up automatic weekly account deposits</FormText>
          </CardBody>
        </Card>
      )}
    </Form>
  );
};

export default LeadCommitment;
