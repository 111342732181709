import React, { useState, useEffect, useMemo } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useNotifications, usePageLoader, useUser } from '@/hooks';
import { SelectField } from '@availity/select';
import { useHistory } from 'react-router-dom';
import { connectionsVsoftQuery } from '@/graphql/queries';
import { updateConnectionsVsoft } from '@/graphql/mutations';
import Switch from 'react-switch';
import { FaSync } from 'react-icons/fa';
import {
  Button,
  Col,
  Row,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  ButtonGroup,
} from 'reactstrap';

export interface VsoftProps {
  values: any;
  setFieldValue: any;
  user: any;
}

const makeRows = () => {
  return (
    <Col>
      <Row>test</Row>
      <Row>test</Row>
    </Col>
  );
};

const VsoftConnection: React.SFC<VsoftProps> = ({
  values,
  setFieldValue,
  user,
}) => {
  const history = useHistory();
  const { complete } = usePageLoader();
  const { create: createNotification } = useNotifications();
  const [stateFilters, setStateFilters] = useState([]);
  const [stateFiltersLoaded, setStateFiltersLoaded] = useState(false);

  const { data, loading, error, refetch } = useQuery(connectionsVsoftQuery);

  const [updateConnection, { loading: updating }] = useMutation(
    updateConnectionsVsoft,
    {
      onCompleted: () => {
        createNotification({
          message: 'Successfully Saved IR Filter',
          type: 'alert',
          action: 'MESSAGE',
          color: 'success',
        });
        refetch();
        // complete();
        // history.push('/profile/me');
      },
      onError: (error) => {
        createNotification({
          message: error.message.replace('GraphQL error:', ''),
          type: 'alert',
          action: 'MESSAGE',
          color: 'danger',
        });

        complete();
      },
    }
  );

  const filterName = useMemo(() => {
    const { filter } = data?.vsoftConnections || {};
    return filter;
  }, [data]);

  useEffect(() => {
    const { criteria = [] } = data?.vsoftConnections || {};
    if (criteria.length > 0) {
      const filters = criteria.find((c) => {
        return c.name === 'State';
      });
      if (filters) {
        if (filters.value) {
          const raw = JSON.parse(filters.value);
          setStateFilters(raw);
          setStateFiltersLoaded(true);
        }
      }
    }
  }, [data]);

  if (loading) {
    return null;
  }

  return (
    <>
      <Card className="mt-2">
        <CardHeader className="d-flex justify-content-between">
          <CardTitle
            tag="h5"
            className="py-2 w-100 h4 mb-0 d-flex justify-content-between"
          >
            Adjust your {filterName} IR Filter
            {updating ? (
              <FaSync className="loading-sync" size={24} />
            ) : (
              <ButtonGroup>
                <Button
                  color="primary"
                  disabled={updating}
                  type="submit"
                  onClick={() => {
                    if (data?.vsoftConnections) {
                      if (stateFilters.length === 0) {
                        createNotification({
                          message: `Must select atleast one state to filter by`,
                          type: 'alert',
                          action: 'MESSAGE',
                          color: 'danger',
                        });
                        return;
                      }
                      const { criteria: oldCriteria } = data?.vsoftConnections;
                      const criteria = oldCriteria.map((c) => {
                        if (c.name === 'State') {
                          return { name: c.name, values: stateFilters };
                        }
                      });
                      if (criteria.length > 0) {
                        const criteriaString = JSON.stringify(
                          criteria.filter((c) => {
                            return c;
                          })
                        );
                        updateConnection({
                          variables: { criteria: criteriaString },
                        });
                      }
                    }
                  }}
                >
                  Save
                </Button>
              </ButtonGroup>
            )}
          </CardTitle>
        </CardHeader>
        <CardBody>
          <Col xs={12}>
            {stateFiltersLoaded ? (
              <SelectField
                id="vsoft-state-dropdown"
                name="states"
                label="States"
                labelKey="label"
                valueKey="value"
                placeholder="Select a state"
                defaultValue={stateFilters.map((i: string) => ({
                  label: i,
                  value: i,
                }))}
                onChange={(c) => {
                  console.log(c);
                  if (c) {
                    setStateFilters(c);
                  } else {
                    createNotification({
                      message: `Must select atleast one state to filter by`,
                      type: 'alert',
                      action: 'MESSAGE',
                      color: 'danger',
                    });
                    setStateFilters([]);
                  }
                }}
                options={user?.network.allowedStates.map((i: string) => ({
                  label: i,
                  value: i,
                }))}
                isMulti
                isClearable
              />
            ) : null}
          </Col>
        </CardBody>
      </Card>
    </>
  );
};

export default VsoftConnection;
