/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Table, Button, Row, Col, Badge } from 'reactstrap';
import { Field, Form } from '@availity/form';
import { FieldArray } from 'formik';
import * as yup from 'yup';
import omit from 'lodash.omit';
import classNames from 'classnames';
import { FaHashtag, FaTrash } from 'react-icons/fa';
import { uuid } from 'uuidv4';
import { SelectField } from '@availity/select';
import { useMutation, useQuery } from '@apollo/react-hooks';
// import { getGoHighLevelCampaigns } from '@/graphql/queries';
import { useNotifications, usePageLoader, useUser } from '@/hooks';
import { useHistory } from 'react-router-dom';
import { updateUserProfile } from '@/graphql/mutations';
import { AgentFragment } from '@/graphql/fragments';
import { Vars } from '@/utils';

interface CRMProfileProps {
  userId: string;
  profiles: any[];
  fields: any[];
}

const GoHighLevelProfile: React.FC<CRMProfileProps> = ({
  profiles = [],
  userId = '',
  fields = [],
}) => {
  const options = {
    requestType: 'code',
    redirectUri: `${window.location.origin}/gh/auth`,
    clientId: Vars.goHighLevelClientId,
    scopes: [
      // "calendars.readonly",
      // "campaigns.readonly",
      // "contacts.readonly",
      'users.readonly',
      'locations.readonly',
      'contacts.write',
      // "calendars/events.write",
      // "calendars/events.readyonly"
    ],
  };

  const authUrl = `https://marketplace.gohighlevel.com/oauth/chooselocation?response_type=${
    options.requestType
  }&redirect_uri=${options.redirectUri}&client_id=${
    options.clientId
  }&scope=${options.scopes.join(' ')}`;

  const { user } = useUser();
  const history = useHistory();
  const { complete } = usePageLoader();
  const { create: createNotification } = useNotifications();

  //   const { data } = useQuery(getGoHighLevelCampaigns, { variables: { apiToken } });

  const [updateProfile] = useMutation(updateUserProfile, {
    onCompleted: () => {
      createNotification({
        message: 'Successfully updated Profile.',
        type: 'alert',
        action: 'MESSAGE',
        color: 'success',
      });
      complete();
      history.push('/profile/me');
    },
    // NOT UPDATING PROFILES
    update(cache, { data: { accountUpdate } }) {
      cache.writeFragment({
        id: accountUpdate.id,
        fragment: AgentFragment,
        data: accountUpdate,
      });
    },
    onError: (error) => {
      createNotification({
        message: error.message.replace('GraphQL error:', ''),
        type: 'alert',
        action: 'MESSAGE',
        color: 'danger',
      });

      complete();
    },
  });
  //   const gohighLevelCampaigns = data?.getGoHighLevelCampaigns || [];

  return (
    <Form
      initialValues={{ profiles, userId }}
      onSubmit={(values) => {
        updateProfile({
          variables: {
            input: {
              agentID: user.agentID,
              crmProfiles: {
                vanillaSoftDirect: {
                  ...user!.crmProfiles!.vanillaSoftDirect,
                  label: 'VanillaSoft',
                  profiles: user!.crmProfiles!.vanillaSoftDirect?.profiles?.map(
                    (p) => ({
                      ...omit(p, ['__typename', 'profileID']),
                      id: p.profileID || p.id,
                    })
                  ),
                },
                iSalesCRM: {
                  ...user!.crmProfiles!.iSalesCRM,
                  label: 'iSalesCRM',
                  profiles: user!.crmProfiles!.iSalesCRM?.profiles?.map(
                    (p) => ({
                      ...omit(p, ['__typename', 'profileID']),
                      id: p.profileID || p.id,
                    })
                  ),
                },
                textdrip: {
                  ...user!.crmProfiles!.textdrip,
                  label: 'Textdrip',
                  profiles: user!.crmProfiles!.textdrip?.profiles?.map((p) => ({
                    ...omit(p, ['__typename', 'profileID']),
                    id: p.profileID || p.id,
                  })),
                },
                gohighlevel: {
                  ...user!.crmProfiles!.gohighlevel,
                  profiles: values.profiles.map((p) => {
                    if (!p.id) {
                      return {
                        ...omit(p, ['__typename', 'profileID']),
                        ...p,
                        id: `gohighlevel-${uuid()}`,
                      };
                    }
                    return p;
                  }),
                },
                onlysales: {
                  ...user!.crmProfiles!.onlysales,
                  label: 'OnlySales',
                  profiles: user!.crmProfiles.onlysales?.profiles?.map((p) => ({
                    ...omit(p, ['__typename', 'profileID']),
                    id: p.profileID || p.id,
                    description: p?.description.trim(),
                    crmFields: {
                      integrationLink: p?.crmFields.integrationLink.trim(),
                      authToken: p?.crmFields.authToken.trim(),
                    },
                  })),
                  // profiles: values.profilesOnlySales.map((p) => ({
                  //   ...omit(p, ['__typename', 'profileID']),
                  //   id: p.profileID || p.id,
                  // })),
                },
              },
            },
          },
        });
      }}
    >
      {({ values, submitCount }) => {
        return (
          <>
            <Button
              color="success"
              className="w-100"
              onClick={() => window.location.replace(authUrl)}
            >
              Add Go High Level Location
            </Button>
            <Table className={classNames('border-bottom border-secondary')}>
              <thead>
                <tr>
                  <th colSpan={2} className="border-0">
                    Profiles
                  </th>
                </tr>
              </thead>
              <tbody>
                <FieldArray
                  name="profiles"
                  render={(arrayHelpers) => {
                    return (
                      <>
                        {values.profiles?.map((profile, profileIdx) => (
                          <tr key={profile.id}>
                            <td className="d-flex flex-column">
                              <Row>
                                <Col>
                                  <span>
                                    <FaHashtag className="mb-2" />
                                    <h4 className="d-inline">
                                      {profileIdx + 1}
                                    </h4>
                                  </span>

                                  <div>
                                    <Button
                                      color="danger"
                                      title="Delete Profile"
                                      id={`delete-profile-${profileIdx}`}
                                      onClick={() =>
                                        arrayHelpers.remove(profileIdx)
                                      }
                                    >
                                      <FaTrash />
                                    </Button>
                                  </div>
                                </Col>
                                <Col
                                  xs={10}
                                  style={{
                                    alignContent: 'center',
                                    fontSize: 25,
                                  }}
                                >
                                  {profile.description}
                                </Col>
                              </Row>
                            </td>
                          </tr>
                        ))}
                      </>
                    );
                  }}
                />
              </tbody>
            </Table>

            <>
              <Button color="secondary" type="reset">
                Reset Changes
              </Button>
              <Button color="primary" className="float-right" type="submit">
                Save Changes
              </Button>
            </>
          </>
        );
      }}
    </Form>
  );
};

export default GoHighLevelProfile;
