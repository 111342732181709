import React, { useState, useEffect } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { ListGroup, Card, CardHeader, CardTitle, Button } from 'reactstrap';
import Pagination, { PaginationContent } from '@availity/pagination';
import { RouteComponentProps } from 'react-router-dom';
import { useNotifications, usePageLoader } from '@/hooks';
import { ProfileEditAgentsModal } from '@/modals';
import { Form } from '@availity/form';
import { NoItemsMessage, SearchSummary } from '@/components';
import AgentListItem from './AgentListItem';
import { useQuery } from '@apollo/react-hooks';
import { agentPagination } from '@/graphql/queries';
import { useUser } from '@/hooks';
import useProfile from '../useProfile';
import SearchControls from './SearchControls';

const admin_roles = ['manager', 'admin', 'super_admin'];

const Agents: React.SFC = () => {
  const { start, complete } = usePageLoader();
  const [resetCount, setResetCount] = useState(0); // Todo find better way to force refresh
  const client = useApolloClient();
  const [agentsModal, setAgentsModal] = useState(false);
  const toggleModal = (): void => setAgentsModal((o) => !o);
  const { user: currentUser } = useUser();
  const isJustAgent = !admin_roles.includes(currentUser.role);
  const {
    flags: { allowSelfEdit },
  } = currentUser;
  const { data: user } = useProfile();

  return (
    <Form
      initialValues={{
        searchText: '',
      }}
      onSubmit={() => {}}
    >
      {({ values, resetForm }) => {
        useEffect(() => {
          resetForm();
        }, [user?.agentID]);

        const fetchItems = async (
          currentPage: number,
          itemsPerPage: number
        ): Promise<any> => {
          start();
          const response = await client.query<any>({
            query: agentPagination,
            fetchPolicy: 'network-only',
            variables: {
              filter: {
                agentID: user?.agentID,
                q: values.searchText,
                mainGroupID: user?.mainGroupID,
              },
              perPage: itemsPerPage,
              page: currentPage,
              orderBy: {
                firstName: 'ASC',
              },
            },
          });

          const currentPageData = response.data?.agentPagination.items;

          complete();
          return {
            totalCount: response.data.agentPagination.count,
            items: currentPageData,
          };
          // }
        };

        return (
          <div>
            <Card className="mb-3">
              <CardHeader>
                <CardTitle tag="h5" className="py-2 h4 mb-0">
                  Manage Agents
                  <Button
                    className="float-right"
                    color="primary"
                    disabled={!allowSelfEdit && isJustAgent}
                    onClick={toggleModal}
                  >
                    Edit Agents
                  </Button>
                </CardTitle>
              </CardHeader>
            </Card>
            <Pagination
              items={fetchItems}
              itemsPerPage={25}
              resetParams={[user?.agentID, values.searchText, resetCount]}
            >
              <SearchControls showManager={false} />
              <SearchSummary
                className="d-flex justify-content-end py-3"
                hideBadges
              />
              <PaginationContent
                component={AgentListItem}
                itemKey="agentID"
                containerTag={ListGroup}
              />
              <NoItemsMessage className="d-flex justify-content-center align-items-center pt-2 pb-2 h5">
                No Agents Found.
              </NoItemsMessage>
            </Pagination>
            <ProfileEditAgentsModal
              onClosed={() => {
                setResetCount((i) => i + 1);
              }}
              isOpen={agentsModal}
              toggle={toggleModal}
            />
          </div>
        );
      }}
    </Form>
  );
};

export default Agents;
